import FileText from "@/assets/FileText";
import { getFileIcon } from "@/utils/components";
import { friendlyOrgName, getIconSrc, getLocale, handleOpenLink } from "@/utils/utils";
import { ExternalLink } from "lucide-react";
import { TypographyLabel } from "./ui/Typography";
import { useContext } from "react";
import { UserContext } from "@/contexts/UserContext";

interface SourceProps {
    id: string
    url: string
    title: string
    fileType: string
    updatedAt: string
    showBorder?: boolean
    isDesiaLibrary?: boolean
}

const Source = ({ id, url, title, fileType, updatedAt, showBorder, isDesiaLibrary }: SourceProps) => {
    const userContext = useContext(UserContext)
    const isDocument = !id.includes("web")
    const locale = getLocale();
    const updatedDateString = new Date(updatedAt).toLocaleDateString(locale, {
        day: "numeric",
        month: "short",
        year: "numeric",

    });

    const getHostname = () => {
        try {
            const { hostname } = new URL(url);
            return hostname
        } catch (e) {
            return ''
        }
    }

    const style = `flex p-3 group ${showBorder ? 'border' : ''} border-system-border-light bg-system-surface hover:bg-system-hover rounded-sm cursor-pointer`

    return (
        <div key={`source_${id}`} className={style}
            onClick={() => {
                handleOpenLink({
                    id: id,
                    url: url,
                    title: title,
                    window
                })
            }}
        >
            <div className="flex flex-col gap-2 flex-grow">
                <div className="flex gap-2 items-center">
                    {isDocument ?
                        <div className="p-[2.5px] rounded-[2px] border border-system-border-light">
                            <FileText className="w-3 h-3" />
                        </div>
                        :
                        <img src={getIconSrc(getHostname())} className="h-4 w-4 border border-system-border-light rounded-sm" />
                    }

                    {isDocument ?
                        <TypographyLabel className="text-system-body">
                            {`${isDesiaLibrary ? 'Desia library' : friendlyOrgName(userContext.user?.app_metadata.organization_id)}, ${updatedDateString}`}
                        </TypographyLabel>
                        :
                        <TypographyLabel className="text-system-body">
                            {getHostname()}
                        </TypographyLabel>
                    }

                </div>
                <div className="flex gap-2 items-center">
                    {isDocument && (
                        getFileIcon(fileType, 'w-6 h-6')
                    )}

                    <div className="font-label font-medium text-system-primary line-clamp-1">{title}</div>
                </div>
            </div>
            <ExternalLink className="w-6 h-6 hidden group-hover:block" />
        </div>
    )
}

export default Source