import { SourceDocument } from "@/types/types"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { getIconSrc, handleOpenLink } from "@/utils/utils";
import { FileIcon } from "lucide-react";
import { getGlobalUniqueDocuments } from "@/utils/components";

interface IProps {
    documents: SourceDocument[];
}

// todo: consider unique documents as prop
// todo: support tabbing through links
export function Sources({ documents }: IProps) {
    const uniqueDocuments = getGlobalUniqueDocuments(documents);
    const webpages = uniqueDocuments.filter(d => d.document_id.includes("web"));
    const internalDocs = uniqueDocuments.filter(d => !d.document_id.includes("web"));

    const hasWebpages = webpages.length > 0;
    const hasInternalDocs = internalDocs.length > 0;

    const defaultValue = hasWebpages ? "webpages" : "internal";

    return (
        <div className="bg-white overflow-y-scroll">
            <Tabs defaultValue={defaultValue} className="max-w-full">
                <TabsList className="grid w-full grid-cols-2">
                    {hasWebpages && <TabsTrigger value="webpages">Webpages</TabsTrigger>}
                    {hasInternalDocs && <TabsTrigger value="internal">Internal Documents</TabsTrigger>}
                </TabsList>

                {hasWebpages && (
                    <TabsContent value="webpages" className="">
                        {webpages.map(d => {
                            const { hostname } = new URL(d.url);
                            const iconSrc = getIconSrc(hostname);
                            return (
                                <div key={`source_${d.document_id}`} className="py-4 px-4 border rounded-md mb-2 text-sm hover:bg-blue-50 hover:cursor-pointer"
                                    onClick={() => {
                                        handleOpenLink({
                                            id: d.document_id,
                                            url: d.url,
                                            title: d.title,
                                            window
                                        })
                                    }}
                                >
                                    <div className="flex gap-1 items-center mb-1">
                                        <img src={iconSrc} className="h-4 w-4 mr-2 border rounded-sm" />
                                        <span className="font-light">
                                            {hostname}
                                        </span>
                                    </div>
                                    <div className="truncate text-ellipsis">{d.text || d.title}</div>
                                </div>
                            )
                        })}
                    </TabsContent>
                )}

                {hasInternalDocs && (
                    <TabsContent value="internal" className="">
                        {internalDocs.map(d => {
                            return (
                                <div key={`source_${d.document_id}`} className="py-4 px-4 border rounded-md mb-2 text-sm hover:bg-blue-50 hover:cursor-pointer"
                                    onClick={() => {
                                        handleOpenLink({
                                            id: d.document_id,
                                            url: d.url,
                                            title: d.title,
                                            window
                                        })
                                    }}
                                >
                                    <div className="flex gap-1 items-center mb-1">
                                        <FileIcon className="h-4 w-4 mr-2 border" />
                                        <span className="font-light">
                                            {d.title}
                                        </span>
                                    </div>
                                    <div className="truncate text-ellipsis">{d.text || d.title}</div>
                                </div>
                            )
                        })}
                    </TabsContent>
                )}
            </Tabs>
        </div>
    )
}
