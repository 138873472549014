import { WEB_SERVER_ENDPOINT } from '@/constants';
import { ResAppUser } from '@/types/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export interface UserState {
    user: {
        profile: ResAppUser | null,
        status: 'idle' | 'loading' | 'error' | 'success',
    }
    connection: {
        status: 'idle' | 'connecting' | 'error' | 'connected' | 'reconnecting' | 'disconnected',
        auth: {
            ticket: string | null,        
            status: 'unauthorized' | 'authorizing' | 'authorized',
        }
    }
}

const initialState: UserState = {
    user: {
        profile: null,
        status: 'idle',
    },
    connection: {
        status: 'idle',
        auth: {
            ticket: null,
            status: 'unauthorized'
        }
    }
}

export const fetchUserAuthTicket = createAsyncThunk<string>(
    'user/fetchAuthTicket',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/user/ws-ticket`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(data)
        }
        return data.ticket
    },
)

export const fetchUserProfile = createAsyncThunk<ResAppUser>(
    'user/fetchUserProfile',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/user/profile`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        connect: (state) => {
            state.connection.status = 'connecting';
        },
        authorize: (state) => {
            state.connection.auth.status = 'authorizing';
        },
        connected: (state) => {
            state.connection.status = 'connected';
        },
        disconnect: (state) => {
            state.connection.status = 'disconnected';
        },
        reconnect: (state) => {
            state.connection.status = 'reconnecting';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserAuthTicket.pending, (state) => {
            state.connection.auth.status = 'authorizing'
            state.connection.auth.ticket = null
        })
        builder.addCase(fetchUserAuthTicket.fulfilled, (state, action) => {
            state.connection.auth.status = 'authorized'
            state.connection.auth.ticket = action.payload
        })
        builder.addCase(fetchUserAuthTicket.rejected, (state) => {
            state.connection.auth.status = 'unauthorized'
            state.connection.auth.ticket = null
        })

        builder.addCase(fetchUserProfile.pending, (state) => {
            state.user.status = 'loading'
            state.user.profile = null
        })
        
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.user.status = 'success'
            state.user.profile = action.payload
        })

        builder.addCase(fetchUserProfile.rejected, (state) => {
            state.user.status = 'error'
            state.user.profile = null
        })
    },
})

export const { connect, authorize } = userSlice.actions

export default userSlice.reducer
