import { ReactNode, createContext, useState } from "react";

interface ILayoutContext {
    showSidebar: boolean,
    toggleShowSidebar: (value: boolean) => void
}
interface IProviderProps {
    children: ReactNode;
}
const initialContextValue = {
    showSidebar: true,
    toggleShowSidebar: () => {},
} satisfies ILayoutContext

export const LayoutContext = createContext<ILayoutContext>(initialContextValue);

function LayoutProvider({ children }: IProviderProps) {
    const [showSidebar, setShowSidebar] = useState<boolean>(true);
    
    function toggleShowSidebar(value: boolean) {
        setShowSidebar(value)
    }

    return (
        <LayoutContext.Provider value={{
            showSidebar,
            toggleShowSidebar
        }}>
            {children}
        </LayoutContext.Provider>
    )
}

export { LayoutProvider };
