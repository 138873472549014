import "./ThreadsTeaser.css";
import { useContext, useEffect } from "react";
import { NavigateFunction, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useSocketQuery } from "../hooks/useSocketQuery";
import { ChatSummary, DESIA_EVENT, ResponseChatList } from "../types/types";
import { getMessageId, getTimestamp } from "../utils/utils";
import { RECENT_THREADS_LIMIT } from "../constants";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "../contexts/AssistantContext";

function ThreadTeaserItem({ summary, navigate }: { summary: ChatSummary, navigate: NavigateFunction }) {
    const shortTitle = summary.query;
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const pathname = location.pathname;
    const isSelected = (pathname === `/assistant/conversation/${summary.conversationId}`) // existing thread
        || (searchParams.get("conversation_id") === summary.conversationId) // new conversation with server response
        || (searchParams.get("request_id") === summary.requestId); // new conversation without server response

    return (
        <div className={`threads-teaser-item ${isSelected ? "sidebar-menu-item-selected" : ""}`} onClick={() => {
            if (summary.conversationId) {
                return navigate(`/assistant/conversation/${summary.conversationId}`);
            }
            if (summary.requestId) {
                return navigate(`/assistant/ask/new/?request_id=${summary.requestId}`);
            }
            console.error(`[desia-web-app]: missing teaser link for summary ${JSON.stringify(summary)}`);
        }}>
            {shortTitle}
        </div>
    )
}

export function ThreadsTeaser() {
    const { store, dispatch } = useContext(AssistantStoreContext);

    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const { executeQuery } = useSocketQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
            requestId: "list",
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: true,
            cacheTimeoutMs: 60_000,
            callback: (response) => {
                dispatch({
                    action: ASSISTANT_ACTIONS.FETCH_THREADS,
                    data: response.data as ResponseChatList[]
                })
            }
        }
    });

    useEffect(() => {
        if (user) {
            executeQuery({
                event: DESIA_EVENT.CHAT_LIST,
                request: {
                    userId: user?.sub,
                    requestId: "list",
                    timestamp: getTimestamp(),
                    params: {}
                },
            })
        }
    }, [user])

    const list = store.list.sort((a,b) => b.timestamp - a.timestamp).filter((_, idx) => idx < RECENT_THREADS_LIMIT);

    return (
        <div className="threads-teaser-container">
            {list.map(li => {
                return (
                    <ThreadTeaserItem key={getMessageId(li)} summary={li} navigate={navigate} />
                )
            })}
        </div>
    )
}