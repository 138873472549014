import { useContext } from "react";
import "./Threads.css";
import { useSocketQuery } from "../hooks/useSocketQuery";
import { ChatSummary, DESIA_EVENT, ResponseChatList } from "../types/types";
import { checkUserFlag, getMessageId, getTimestamp } from "../utils/utils";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "../contexts/AssistantContext";
import { Threads as NewThreads } from "./Threads/Threads";

function Thread({ summary, navigate }: { summary: ChatSummary, navigate: NavigateFunction }) {
    const date = new Date(summary.timestamp);
    const friendlyDate = Intl.DateTimeFormat().format(date);
    const timeSince = (new Date()).getTime() - summary.timestamp;
    const daysSince = Math.ceil(timeSince / (1000 * 60 * 60 * 24));
    const formattedRelativeDate = daysSince <= 1 ? `today` : `${daysSince} days ago`;
    return (
        <div className="thread">
            <h2 className="thread-title font-h2" onClick={() => {
                navigate(`/assistant/conversation/${summary.conversationId}`);
            }}>{summary.query}</h2>
            <p className="thread-timestamp">{friendlyDate} - {formattedRelativeDate}</p>
        </div>
    )
}

export function ThreadsContainer() {
    if (checkUserFlag("ui: legacy design system")) {
        return <Threads />
    }
    return <NewThreads />
}


export function Threads() {
    const { store, dispatch } = useContext(AssistantStoreContext);
    const navigate = useNavigate();
    const { state: { error, loading } } = useSocketQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
            requestId: "list",
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: false,
            cacheTimeoutMs: 60_000,
            callback: (response) => {
                dispatch({
                    action: ASSISTANT_ACTIONS.FETCH_THREADS,
                    data: response.data as ResponseChatList[]
                })
            }
        }
    });

    return (
        <div className="threads-container">
            <h1 className="title font-h1">Threads</h1>
            
            {error && (
                <ErrorMessage message={`We failed to list threads, try again later`} />
            )}

            {loading && (
                <>
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                    <div className="skeleton loading-thread" />
                </>
            )}
            {store.list.sort((a,b) => b.timestamp - a.timestamp).map(li => {
                return (
                    <Thread key={getMessageId(li)} summary={li} navigate={navigate} />
                )
            })}
        </div>
    )
}
