import { ResponseChatStream, Citation, SourceDocument, SystemMessage } from "@/types/types";
import { checkWebLink, getFileId } from "./utils";
import FileType from "@/assets/FileType";
import FileSpreadsheet from "@/assets/FileSpreadsheet";
import FileBarChart from "@/assets/FileBarChart";
import { ReactNode } from "react";
import FileText from "@/assets/FileText";

// web sources could have the same document_id
// so instead use the url as the identifier
function getGlobalDedupedId(d: SourceDocument) {
    const isWebLink = checkWebLink(d.url);
    if (isWebLink) {
        return d.url;
    }
    // file
    const fileId = getFileId(d.document_id);
    return fileId;
}

export function getGlobalUniqueDocuments(documents: SourceDocument[]) {
    let deduped: SourceDocument[] = [];
    let dedupedIds = new Map();
    documents.forEach(d => {
        const dId = getGlobalDedupedId(d);
        if (!dedupedIds.has(dId)) {
            deduped.push(d);
            dedupedIds.set(dId, true);
        }
    });
    return deduped;
}

export function mergeIncomingStreamWithSystemMessage(prev: SystemMessage, incoming: ResponseChatStream) {
    let merged = { ...prev }
    switch (incoming.event_type) {
        case 'stream-start': {
            break;
        }
        case 'search-results': {
            merged.data.documents = incoming.body.documents;
            break;
        }
        case 'text-generation': {
            merged.data.text = incoming.body.text;
            break;
        }
        case 'citation-generation': {
            merged.data.citations = [
                ...(merged.data.citations || []),
                ...(incoming.body.citations || [])
            ]
            break;
        }
        case 'stream-end': {
            // todo: consider only setting finished flag
            merged.data.text = incoming.body.text;
            merged.data.documents = incoming.body.documents;
            merged.data.citations = incoming.body.citations;
            merged.data.isFinished = true;
            break;
        }

        default:
            break;
    }
    return merged;
}

export function filterDocumentsByCited(documents: SourceDocument[], citations: Citation[], skipPendingCitations = false) {
    if (skipPendingCitations && citations.length === 0) {
        return documents;
    }
    const citedDocumentIds = [...new Set(citations.map(c => c.document_ids).flat())];
    const filtered = documents.filter(d => {
        const shouldInclude = citedDocumentIds.includes(d.document_id);
        return shouldInclude;
    })
    return filtered;
}

export const getFileIcon = (type: string, className?: string): ReactNode => {
    const style = `w-4 h-4 shrink-0 ${className}`
    switch (type.toLowerCase()) {
        case "pdf": return <FileType className={style} />
        case "text":
        case "csv":
        case "markdown":
        case "word":
            return <FileText className={style} />
        case "spreadsheet":
            return <FileSpreadsheet className={style} />
        case "presentation":
            return <FileBarChart className={style} />
        default:
            return <FileType className={style} />
    }
}