import { Link } from "react-router-dom";
import { RecentThreads } from "./RecentThreads";
import { ChatSummary } from "@/types/types";
import { WarnOnNavigate } from "../WarnOnNavigate";
import { TypographyBody } from "../ui/Typography";
import MessageCircleQuestion from "@/assets/MessageCircleQuestion";

const baseStyles = "flex items-center gap-2 transition-all";

export function Sidebar({ threads }: { threads: ChatSummary[] }) {
    return (
        <nav className="flex flex-col gap-6 items-start font-medium h-full">
            <WarnOnNavigate to="/assistant/ask/" component={
                <Link
                    to="/assistant/ask"
                    className={`${baseStyles} text-link`}
                >
                    <MessageCircleQuestion className="h-6 w-6 stroke-link" />
                    <TypographyBody isStrong={true}>
                        Ask Desia
                    </TypographyBody>
                </Link>
            } />
            <WarnOnNavigate to="/threads" component={
                <Link
                    to="/threads"
                    className={`${baseStyles} text-system-primary`}
                >
                    <TypographyBody isStrong={true}>
                        Threads
                    </TypographyBody>
                </Link>
            } />

            <RecentThreads threads={threads} />

            <WarnOnNavigate to="/reports" component={
                <Link
                    to="/reports"
                    className={`${baseStyles} text-system-primary`}
                >
                    <TypographyBody isStrong={true}>
                        Reports
                    </TypographyBody>
                </Link>
            } />
            <WarnOnNavigate to="/resources" component={
                <Link
                    to="/resources"
                    className={`${baseStyles} text-system-primary`}
                >
                    <TypographyBody isStrong={true}>
                        Documents
                    </TypographyBody>
                </Link>
            } />
        </nav>
    )
}