import { ArrowRight } from "lucide-react";
import { TypographyBody } from "../ui/Typography";
import { Button } from "../ui/button";

export function AskDesiaLink({ query, handleClick }: { query: string | null, handleClick: () => void }) {
    if (typeof query !== "string") {
        return null;
    }
    return (
        <Button variant={"tertiary"} size={"sm"} onClick={handleClick}>
            <TypographyBody>Ask Desia “{query}”</TypographyBody>
            <ArrowRight className="size-6 stroke-[1.5px]" />
        </Button>
    )
}