import { DedupedSearchQueryItem } from "@/types/types";
import { Skeleton } from "../ui/skeleton";
import { SearchResult } from "./SearchResult";
import { ErrorMessage } from "../ErrorMessage";

export function SearchResults({ error, loading, data }: { error: any, loading: boolean, data: DedupedSearchQueryItem[] }) {
    if (loading) {
        return (
            <div>
                <Skeleton className="h-60" />
                <Skeleton className="h-60" />
                <Skeleton className="h-60" />
                <Skeleton className="h-60" />
            </div>
        )
    }
    if (error) {
        return (
            <ErrorMessage message="We failed to search for resources, please try again shortly" />
        )
    }
    return (
        <div className="flex flex-col gap-6">
            {data.map((resource) => {
                return (
                    <SearchResult resource={resource} key={resource.id} />
                )
            })}
        </div>
    )
}
