import { ReactNode, useEffect, useRef, useState } from "react";

export function ToolPanel({ show, offset, component, hide }: { show: boolean, offset: number, component: ReactNode, hide: () => void }) {
    const [topMargin, setTopMargin] = useState(0)
    const [panelWidth, setPanelWidth] = useState(0)
    const [componentHeight, setComponentHeight] = useState(0)

    const componentRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const fixedContainerRef = useRef<HTMLDivElement>(null)

    const containerWidth = containerRef.current?.clientWidth || 0
    const fixedContainerHeight = fixedContainerRef.current?.clientHeight || 0

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setComponentHeight(event[0].contentBoxSize[0].blockSize)
        });

        const element = document.getElementById("tool-panel-component")
        if (element) {
            resizeObserver.observe(element);
        }
    }, []);

    useEffect(() => {
        const defaultMargin = offset - 120
        let margin = 0

        if (componentHeight >= fixedContainerHeight) {
            margin = 0
        } else if (defaultMargin + componentHeight > (fixedContainerHeight)) {
            margin = fixedContainerHeight - componentHeight + 128
        } else {
            margin = defaultMargin
        }

        setTopMargin(margin)
    }, [offset, componentHeight, fixedContainerHeight])

    useEffect(() => {
        setPanelWidth(containerWidth + 84)
    }, [containerWidth])

    return (
        <div id="tool-panel-container" className={`${show ? "w-full" : "w-0"} h-full`} ref={containerRef}>
            <div onClick={() => hide()} className="fixed top-0 left-0 w-full h-full"></div>

            <div className={` flex flex-col justify-between rounded-lg ${show ? "animate-fade-in" : "animate-fade-out"} `}>
                <div className="overflow-visible flex">
                    <div className="flex transition-all h-[calc(100vh-80px-32px-128px)] mb-4 flex-grow fixed !max-w-[518px] pointer-events-none z-[3]" ref={fixedContainerRef}>
                        <div id="tool-panel-component" className="h-fit min-h-0 max-h-[calc(100vh-80px-32-128px)] pointer-events-auto" style={{ width: `${panelWidth}px`, marginTop: `${topMargin}px`, marginLeft: `-${84}px` }} ref={componentRef}>
                            {component}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
