import "./Layout.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router"
import { UserContext } from "../contexts/UserContext";
import { SpotlightSearch } from "./SpotlightSearch";
import { useLocation } from "react-router-dom";
import upIcon from "../assets/up-arrow-svgrepo-com.svg";
import downIcon from "../assets/svgviewer-downarrow.svg"
import { useWindowSize } from "../hooks/useWindowSize";
import { FF_CHAT, FF_THREADS, breakpoints } from "../constants";
import logo from "../assets/logo-col-dark.png";
import { checkUserFlag, cleanupIdentifiers, shortString } from "../utils/utils";
import { ThreadsTeaser } from "./ThreadsTeaser";
import { LayoutContainer as NewLayout } from "./layout/Layout";

type LayoutProps = {
    component: React.ReactElement
    tall?: boolean
    scrollable?: boolean
}

function isSelected(location: string, menuItemPath: string) {
    if (menuItemPath.toLowerCase().includes(location.toLocaleLowerCase())) {
        return true;
    }
    return false;
}

export function Layout(props: LayoutProps) {
    if (checkUserFlag("ui: legacy design system")) {
        return (
        <LegacyLayout component={props.component} />
        )
    }
    return (
        <NewLayout component={props.component} tall={props.tall} scrollable={props.scrollable}/>
    )
}

export function LegacyLayout(props: LayoutProps) {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(true);
    const size = useWindowSize();

    function conditionallyCollapseSidebar(bp: number) {
        if (window.innerWidth < bp) {
            setShowSidebar(false);
        } else {
            setShowSidebar(true);
        }
    }
    function handleNavigate(p: string) {
        navigate(p);
        conditionallyCollapseSidebar(breakpoints.sm);
    }

    useEffect(() => {
        return () => {
            cleanupIdentifiers();
        }
    }, [])

    useEffect(() => {
        conditionallyCollapseSidebar(breakpoints.xl);
    }, [size.width]);

    if (user.status === "error") {
        navigate("/login");
    }

    const shortEmail = shortString(user.user?.email || "", 20);

    return (
        <div className="layout-container">
            <div className="sidebar-container">
                <div className="app-bar">
                    <img aria-label='toggle menu icon' src={showSidebar ? upIcon : downIcon} className='toggle-menu-icon'
                        onClick={() => {
                            setShowSidebar(!showSidebar);
                        }}
                    />
                    <div className="sidebar-brand">
                        <img alt="DESIA logo" src={logo} className="layout-logo" />
                    </div>
                    <div />
                </div>
                <div className={`sidebar-inner ${showSidebar ? "" : "hidden"}`}>
                    
                    <div className="sidebar-top">
                        <div className="sidebar-menu-items">
                            <div className="layout-spotlight-search-container">
                                <SpotlightSearch searchCallback={(): void => {
                                    conditionallyCollapseSidebar(breakpoints.sm);
                                }} />
                            </div>
                            {FF_CHAT && (
                                <div className={`sidebar-menu-item ${isSelected(location.pathname, "/assistant/ask/") && "sidebar-menu-item-selected"}`} onClick={() => {
                                    handleNavigate("/assistant/ask/");
                                }}>
                                    Ask
                                </div>
                            )}
                            {FF_THREADS && (
                                <>
                                    <div className={`sidebar-menu-item ${isSelected(location.pathname, "/threads") && "sidebar-menu-item-selected"}`} onClick={() => {
                                        handleNavigate("/threads");
                                    }}>
                                        Threads
                                    </div>
                                    <ThreadsTeaser />
                                </>
                            )}
                            
                            <div className={`sidebar-menu-item ${isSelected(location.pathname, "/resources") && "sidebar-menu-item-selected"}`} onClick={() => {
                                handleNavigate("/resources");
                            }}>
                                Documents
                            </div>
                        </div>
                    </div>


                    <div className="sidebar-bottom">
                        <div className={`sidebar-menu-item ${isSelected(location.pathname, "/profile") && "sidebar-menu-item-selected"}`} onClick={() => {
                            handleNavigate("/profile");
                        }}>
                            <div className="user-menu-item">
                                <img src={user?.user?.picture} className="user-icon" />
                                <span>{shortEmail}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="main-container-inner">
                    {props.component}
                </div>
            </div>
        </div>
    )
}
