const ArrowRight = ({ className }: { className?: string }) => {
    const style = `stroke-system-primary ${className}`
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={style} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_162)">
                <path d="M5 12H19" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 5L19 12L12 19" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1_162">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArrowRight