import "./SpotlightSearch.css";
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import icon from "../assets/icons8-search.svg";
import docIcon from "../assets/icons8-document-30.svg";
import filterIcon from "../assets/filter-svgrepo-com.svg";
import { useDebounce } from '../hooks/useDebounce';
import { DESIA_EVENT, WebSocketResponseWrapper, ResponseAutoComplete } from '../types/types';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "react-tooltip";
import { WebSocketContext } from "../contexts/WebSocketContext";


const suggestions = [
    {
        id: 1,
        icon: docIcon,
        filename: "Market_analysis-chemicals.pdf",
        metadata: [
            {
                name: "market overview"
            }
        ]
    },
    {
        id: 2,
        icon: docIcon,
        filename: "Project_eagle.pdf",
        metadata: [
            {
                name: "diligence document"
            }
        ]
    },
    {
        id: 3,
        icon: docIcon,
        filename: "Project_eagle_IM.pdf",
        metadata: [
            {
                name: "investment memorandum"
            }
        ]
    },
]

function getGuideText(query: string, autocomplete: string[]) {
    if (autocomplete.length > 0) {
        return 'Press Enter to search or select an option';
    }
    if (query) {
        return 'Press Enter to search';
    }
    return 'Start typing';
}


export function SpotlightSearch(props: { searchCallback: () => void }) {
    const secureSocket = useContext(WebSocketContext);
    const socket = secureSocket.socket!;
    const [isOpen, setIsOpen] = useState(false);
    const [q, setQ] = useState("");
    const debouncedQ = useDebounce(q, 150);
    const [autocomplete, setAutocomplete] = useState<WebSocketResponseWrapper<ResponseAutoComplete> | null>(null);

    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    function handleAutocomplete(res: WebSocketResponseWrapper<ResponseAutoComplete>) {
        setAutocomplete(res);
    }

    function onQuery(e: React.ChangeEvent<HTMLInputElement>) {
        setQ(e.target.value);
    }

    function handleNavigate(query: string) {
        props.searchCallback();
        setTimeout(() => {
            navigate(`/search?q=${encodeURIComponent(query)}`);
            closeModal();
        }, 0);
    }

    // todo: refactor to use onKeyDown, event.preventDefault as per AskTextbox
    useEffect(() => {
        function handleKeydown(event: KeyboardEvent) {
            if (event.key === "Enter" && inputRef.current?.value) {
                handleNavigate(inputRef.current.value);
            }
        }

        addEventListener("keydown", handleKeydown);
        socket.on(DESIA_EVENT.SEARCH_AUTOCOMPLETE, handleAutocomplete);

        return () => {
            socket.on(DESIA_EVENT.SEARCH_AUTOCOMPLETE, handleAutocomplete);
            removeEventListener("keydown", handleKeydown);
        }
    }, []);

    useEffect(() => {
        if (debouncedQ) {
            socket.emit(DESIA_EVENT.SEARCH_AUTOCOMPLETE, { query: debouncedQ });
        } else {
            setAutocomplete(null);
        }
    }, [debouncedQ]);

    const hasAutocompleteCandidates = Boolean(autocomplete?.data?.length);
    const showSuggestions = false;

    const guideText = getGuideText(`${debouncedQ}`, autocomplete?.data || []);
    return (
        <>
            <div className="spotlight-search-button" onClick={openModal}>
                <span>Search</span>
                <img aria-label='search icon' src={icon} className='spotlight-search-button-icon' />
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/75" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center p-4 text-center spotlight-modal">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                                    <div>
                                        <div className="spotlight-search-main-controls">
                                            <img src={icon} className='spotlight-search-main-controls-icon' />
                                            <input
                                                ref={inputRef}
                                                tabIndex={1}
                                                type='text'
                                                className="spotlight-search-input"
                                                placeholder="Search Desia..."
                                                autoFocus={true}
                                                onChange={onQuery}
                                                value={q}
                                            />
                                            <button data-tooltip-id="tooltip-spotlight-search-filters"
                                                data-tooltip-content="Coming soon: search filters"
                                                data-tooltip-place="top" tabIndex={2} className="spotlight-search-filter-button" disabled>
                                                <img src={filterIcon} className='spotlight-search-filter-button-icon' />
                                            </button>
                                        </div>

                                        <Tooltip id="tooltip-spotlight-search-filters" />
                                        <div className='spotlight-search-filters'></div>

                                        <div className='spotlight-results-container'>

                                            {hasAutocompleteCandidates && (
                                                <div className='autocomplete-candidates'>
                                                    {autocomplete?.data?.map((c, idx) => {
                                                        return (
                                                            <div tabIndex={(idx + 2)} key={`autocomplete-candidate-${c}`} className='autocomplete-candidate-wrapper'>
                                                                <div className='autocomplete-candidate' onClick={() => {
                                                                    handleNavigate(c);
                                                                }}>
                                                                    {c}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}

                                            {showSuggestions && (
                                                <div className='spotlight-search-suggestions'>
                                                    <div className='spotlight-search-suggestions-description'>Suggested for you</div>
                                                    {suggestions.map((s, idx) => {
                                                        return (
                                                            <div tabIndex={idx + 100} key={s.id} className='spotlight-search-suggestion'>
                                                                <div className='spotlight-search-suggestion-document'>
                                                                    <img className='spotlight-search-suggestion-document-icon' src={s.icon} />
                                                                    <div className='spotlight-search-suggestion-document-filename'>
                                                                        {s.filename}
                                                                    </div>
                                                                </div>
                                                                <div className='spotlight-search-suggestion-document-metadata'>
                                                                    {s.metadata.map(m => {
                                                                        return (
                                                                            <span key={m.name} className='spotlight-search-suggestion-document-metadata-item'>
                                                                                {m.name}
                                                                            </span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}

                                            <div className={`spotlight-search-guide ${autocomplete?.data?.length ? "spotlight-search-guide-autocomplete" : ""}`}>
                                                <span className="spotlight-search-guide-text">
                                                    {guideText}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
