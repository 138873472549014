import { useContext } from "react";
import { useSocketQuery } from "../../hooks/useSocketQuery";
import { ChatSummary, DESIA_EVENT, ResponseChatList } from "../../types/types";
import { getMessageId, getTimestamp } from "../../utils/utils";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ErrorMessage } from "../ErrorMessage";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "../../contexts/AssistantContext";
import { Skeleton } from "../ui/skeleton";
import { TypographyBody, TypographyH3 } from "../ui/Typography";

function Thread({ summary, navigate, isLast }: { summary: ChatSummary, navigate: NavigateFunction, isLast?: boolean }) {
    const date = new Date(summary.timestamp);
    const friendlyDate = Intl.DateTimeFormat().format(date);
    const timeSince = (new Date()).getTime() - summary.timestamp;
    const daysSince = Math.ceil(timeSince / (1000 * 60 * 60 * 24));
    const formattedRelativeDate = daysSince <= 1 ? `today` : `${daysSince} days ago`;
    let baseClass = "border-b py-6";
    if (isLast) {
        baseClass = "py-6";
    }
    return (
        <div
            className={baseClass}
        >
            <TypographyBody
                isStrong={true}
                className="hover:cursor-pointer hover:text-system-primary hover:underline pb-3"
                onClick={() => {
                    navigate(`/assistant/conversation/${summary.conversationId}`);
                }}
            >
                {summary.query}
            </TypographyBody>
            <TypographyBody className="">
                {friendlyDate} - {formattedRelativeDate}
            </TypographyBody>
        </div>
    )
}

export function Threads() {
    const { store, dispatch } = useContext(AssistantStoreContext);
    const navigate = useNavigate();
    const { state: { error, loading } } = useSocketQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
            requestId: "list",
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: false,
            cacheTimeoutMs: 60_000,
            callback: (response) => {
                dispatch({
                    action: ASSISTANT_ACTIONS.FETCH_THREADS,
                    data: response.data as ResponseChatList[]
                })
            }
        }
    });

    return (
        <div
            className="w-full"
        >
            <div
                className="text-center"
            >
                <TypographyH3>Threads</TypographyH3>
            </div>

            {error && (
                <ErrorMessage message={`We failed to list threads, please try again shortly`} className="my-4" />
            )}

            {loading && (
                <div className="py-6 mx-4">
                    <Skeleton className="h-[100px] my-2" />
                    <Skeleton className="h-[100px] my-2" />
                    <Skeleton className="h-[100px] my-2" />
                </div>
            )}
            <div className="py-6">
            {!loading && store.list.sort((a, b) => b.timestamp - a.timestamp).map((li, idx) => {
                const isLast = idx === store.list.length - 1;
                return (
                    <Thread key={getMessageId(li)} summary={li} navigate={navigate} isLast={isLast} />
                )
            })}
            </div>
        </div>
    )
}
