import { WEB_SERVER_ENDPOINT } from "@/constants";
import { WebSocketRequestWrapper } from "@/types/types";
import { io, Socket } from "socket.io-client";
import wait from "wait";

class SocketConnection {
    public socket: Socket | null;
    public dispatch: any;

    constructor() {
        this.socket = null;
        this.dispatch = null;
    }

    setDispatch(dispatch: any) {
        this.dispatch = dispatch;
    }

    async getAuthTicket() {
        try {
            const response = await fetch(`${WEB_SERVER_ENDPOINT}/user/ws-ticket`, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json();
            return data.ticket;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    connect(authTicket: string, reconnect: boolean = false) {
        if (!this.socket || reconnect) {
            this.socket = io(WEB_SERVER_ENDPOINT, {
                path: '/ws',
                auth: {
                    ticket: authTicket
                },
                reconnection: false,
                forceNew: true,
            });

            this.socket.onAny((event, response) => {
                console.info(`${event}: ${JSON.stringify(response, null, 2)}`);
            });

            this.socket.on('disconnect', () => {
                console.error("websocket disconnected unexpectedly. Attempting to reconnect.");
                this.socket?.off('disconnect');
                this.reconnect();
            });
        }
    }

    async reconnect() {
        if (!this.socket) {
            console.error("[reconnection] no socket connection to reconnect to");
            return;
        }
        let attempt = 0;
        const maxAttempts = 20;
        while (attempt < maxAttempts) {
            attempt = attempt + 1;
            console.debug(`[reconnection attempt] #${attempt} @ ${new Date().toISOString()}`);
            const backoff = 1_000 * attempt;
            await wait(backoff);
            if (this.socket.connected) {
                console.debug("[reconnection] successful");
                this.dispatch({ type: "user/connected" });
                break;
            }
            const ticket = await this.getAuthTicket();
            if (ticket) {
                this.connect(ticket, true);
            }
        }

        if (attempt === maxAttempts) {
            console.error("[reconnection] failed");
            this.dispatch({ type: "user/reconnect_failed" });
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    emit(event: string, message: WebSocketRequestWrapper<any>) {
        if (this.socket) {
            this.socket.emit(event, message)
            console.log(`emitted event ${event}: message`)
        }
    }

    on(eventName: string, callback: (message: string | Object) => void) {
        if (this.socket) {
            this.socket.on(eventName, callback)
        }
    }
}

export { SocketConnection }
