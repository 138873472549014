import "./Login.css";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../contexts/UserContext";
import loginIcon from "../assets/login-svgrepo-com.svg";
import { handleLogin } from "../utils/utils";

export function Login() {
    const navigate = useNavigate();
    const { user, status } = useContext(UserContext);

    useEffect(() => {
        if (user && status === 'success') {
            navigate("/assistant/ask");
        }
    }, [user]);

    return (
        <>
            <div className="login-container">
                <div className="login-inner">
                    <h1 className="login-app-title font-h1">DESIA</h1>
                    <button className="login-button" onClick={() => {
                        handleLogin();
                    }}><span>Login</span> <img src={loginIcon} className="login-icon"/></button>
                </div>
            </div>
        </>
    )
}
