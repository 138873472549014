import { useContext, useEffect, useState } from "react";
import { TypographyH3 } from "../ui/Typography";
import { ResourceTable } from "./ResourceTable";
import { ResourceFilter } from "./ResourceFilter";
import { QueryStatus, ResourceFilterOption, ResponseDocument } from "@/types/types";
import { SearchControl } from "./SearchControl";
import { FileUpload } from "./FileUpload";
import { useNavigate } from "react-router-dom";
import { DocumentStoreContext } from "@/contexts/DocumentContext";
import wait from "wait";
import { CustomAlert } from "../CustomAlert";
import { checkUserFlag } from "@/utils/utils";
import { MultiFileUpload } from "./MultiFileUpload";
import { POLL_FILES_DURATION } from "@/constants";

export function ResourcesPage({
    loading,
    errorFetching,
    errorUploading,
    resources,
    handleUploadFile,
    handleListFiles
}: {
    loading: boolean;
    errorFetching: boolean;
    errorUploading: boolean;
    resources: ResponseDocument[];
    handleUploadFile: (file: File) => Promise<void>;
    handleListFiles: () => void;
}) {
    const [resourceFilter, setResourceFilter] = useState<ResourceFilterOption>("all documents");
    const navigate = useNavigate();

    function handleSearch(searchQuery: string) {
        if (searchQuery === "") {
            return;
        }
        navigate(`/resources/search?q=${searchQuery}`);
    }

    async function uploadFile(file: File) {
        handleUploadFile(file)
            .then(() => {
                pollUploadingFiles();
            })
    }

    async function pollUploadingFiles(force?: boolean) {
        let maxPolls = 10;
        let polls = 0;

        while (polls < maxPolls) {
            if (processingFiles.length === 0 && !force) {
                break;
            }

            handleListFiles();
            polls++;
            await wait(POLL_FILES_DURATION);
        }
    }

    const processingFiles = resources.filter(r => !r.document_is_ready_to_use && r.document_processing_status !== "failed");
    const failedFiles = resources.filter(r => r.document_processing_status === "failed");
    const isLegacyUpload = checkUserFlag("files: legacy upload");

    const filteredResources = resources.filter((r) => {
        if (resourceFilter === "company-wide") {
            return r.document_is_part_of_desia_library === false;
        }
        if (resourceFilter === "desia library") {
            return r.document_is_part_of_desia_library === true;
        }
        return true;
    })

    const sortedResources = [...filteredResources].sort((a, b) => {
        const aProcessing = a.document_is_ready_to_use
        const bProcessing = b.document_is_ready_to_use
        
        if (aProcessing === bProcessing) return 0;
        return bProcessing ? -1 : 1;
    });

    useEffect(() => {
        handleListFiles();
    }, [])

    return (
        <div className="sm:max-w-[80%] mx-auto flex flex-col gap-12">
            <div className="text-center">
                <TypographyH3>Documents</TypographyH3>
            </div>

            <SearchControl handleSearch={handleSearch} />

            {errorUploading && (
                <CustomAlert
                    variant="error"
                    title="We could not process all the files"
                    description="We failed to upload that file, please try again shortly"
                />
            )}

            {failedFiles.length > 0 && (
                <div className="w-fit mx-auto">
                    <CustomAlert
                        variant="error"
                        title="We could not process all the files"
                        description="We have notified the team and they will be fixing them as soon as possible."
                    />
                </div>
            )}

            <div className="flex flex-col gap-6">
                <ResourceFilter
                    selectedFilter={resourceFilter}
                    handleFilterChange={(filterName) => {
                        setResourceFilter(filterName);
                    }}
                />

                <div className="flex justify-end">
                    {!isLegacyUpload ? <MultiFileUpload uploadCallback={() => {
                        // hack to refetch after uploading files
                        // this will be fixed when file upload notifications are implemented
                        handleListFiles();
                        setTimeout(() => {
                            pollUploadingFiles(true);
                        }, 1000);
                        
                    }} /> : <FileUpload handleUploadFile={uploadFile} />}
                </div>

                <ResourceTable documents={sortedResources} loading={loading} error={errorFetching} />
            </div>
        </div>
    )
}

export function ResourcesPageContainer() {
    const documentContext = useContext(DocumentStoreContext);
    const loading = documentContext.store.status === QueryStatus.FETCHING;
    const errorFetching = documentContext.store.status === QueryStatus.ERROR_FETCHING;
    const errorUploading = documentContext.store.status === QueryStatus.ERROR_UPLOADING;
    const resources = documentContext.store.data;
    const handleUploadFile = documentContext.handlers.uploadDocument;
    const handleListFiles = documentContext.handlers.listDocuments;

    return (
        <ResourcesPage
            loading={loading}
            errorFetching={errorFetching}
            errorUploading={errorUploading}
            resources={resources || []}
            handleUploadFile={handleUploadFile}
            handleListFiles={handleListFiles}
        />
    )
}
