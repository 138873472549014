const ChevronDown = ({ className }: { className?: string }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_151)">
            <path d="M6 9L12 15L18 9" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1_151">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export default ChevronDown