import { useContext } from "react"
import { CustomAlert } from "../CustomAlert"
import { ToastContext } from "@/contexts/ToastContext"

export const Toast = () => {
    const toastContext = useContext(ToastContext)

    return (
        <div className={`absolute top-6 right-0 pr-6 transition-transform duration-200 ease-in-out z-[40] ${toastContext.isToastShown ? 'translate-x-0' : 'translate-x-full'}`}>
            <CustomAlert
                {...{
                    ...toastContext.shownToast,
                    onCloseClick: () => {
                        toastContext.shownToast?.onCloseClick?.()
                        toastContext.closeToast()
                    }
                }}
            />
        </div>
    )
}