import { LOCAL_STORAGE_TIMEOUT_MS, LOGIN_PATH, LOGOUT_PATH, USER_FEATURE_FLAG_PREFIX, USER_SETTINGS_KEY, WEB_SERVER_ENDPOINT } from "../constants";
import { ExpertModeDocument, ResAppUser, ResponseDocument, SourceDocument, UserFeatureFlag, UserSettings } from "../types/types";

export function getTimestamp(d?: string) {
    if (d) {
        return (new Date(d)).getTime();
    }
    return (new Date()).getTime();
}

export function shortString(str: string, maxLen: number) {
    if (str.length > maxLen) {
        return str.substring(0, maxLen - 3) + "...";
    }
    return str;
}

export function getMessageId({ conversationId, timestamp, requestId }: { conversationId?: string, timestamp: number, requestId?: string }) {
    return `${conversationId || requestId}_${timestamp}`;
}

export function getFileLinkV2(file: ResponseDocument) {
    if (file.document_source === "user_upload") {
        if (file.document_storage_class === "gcs") {
            // signed url
            return `${WEB_SERVER_ENDPOINT}/document/download/${file.document_id}/`;
        }
        if (file.document_storage_class === "local") {
            // old file links
            // will be deprecated / removed in future
            return `${WEB_SERVER_ENDPOINT}/document/content/${file.document_id}/${file.document_name}`;
        }
        console.error("Unknown file storage class given user_upload", file);
    }
    if (file.document_source === "integration") {
        // link to external file e.g. sharepoint
        return file.document_secure_shared_link;
    }
    console.error("Unknown file storage class", file);
}

// deprecate after all files are migrated to GCS
export function getFileLink(fileId: string, filename: string) {
    const url = `${WEB_SERVER_ENDPOINT}/document/content/${fileId}/${filename}`;
    return url;
}

export function timeDifference(start: Date, finish: Date) {
    const t1 = start.getTime();
    const t2 = finish.getTime();
    return t2 - t1;
}

export function exceededDuration(start: Date, finish: Date, maxDuration: number) {
    const td = timeDifference(start, finish);
    return td > maxDuration;
}

export function checkWebLink(url: string) {
    const isWebLink = url.includes("http");
    return isWebLink;
}

export function getFileId(contentId: string) {
    const [fileId] = contentId.split("_");
    return fileId;
}

export function handleOpenLink({ id, url, title, window }: { id: string, url: string, title: string, window: Window }) {
    const isWebLink = checkWebLink(url);
    // url points to either a web link or a file
    if (isWebLink) {
        window.open(url, "_blank");
    } else {
        const fileId = getFileId(id);
        const fileUrl = getFileLink(fileId, title);
        window.open(fileUrl, "_blank");
    }
}

export function handleLogin() {
    window.location.href = `${WEB_SERVER_ENDPOINT}${LOGIN_PATH}`;
}

export function handleLogout() {
    window.location.href = `${WEB_SERVER_ENDPOINT}${LOGOUT_PATH}`;

}

export function plural(text: string, count: number) {
    if (count === 1) return text;
    return `${text}s`;
}

export function getIdentifierKey(requestId: string) {
    return `request_id__${requestId}`;
}

/**
 * Store mapping between requestId <> conversationId
 * to allow transition from new question (page refresh) temp urls
 * to perma urls 
 */
export function saveIdentifier(requestId: string, conversationId: string, timestamp: number) {
    try {
        const identifiers = {
            key: getIdentifierKey(requestId),
            value: JSON.stringify({
                requestId: requestId,
                conversationId: conversationId,
                timestamp: timestamp,
            })
        };
        localStorage.setItem(identifiers.key, identifiers.value);
    } catch (e) {
        console.error(e);
    }
}

export function clearIdentifier(requestId: string) {
    try {
        const key = getIdentifierKey(requestId);
        localStorage.removeItem(key);
    } catch (e) {
        console.error(e);
    }
}

export function getIdentifier(requestId: string) {
    try {
        const key = getIdentifierKey(requestId);
        const identifiers = localStorage.getItem(key);
        if (identifiers) {
            const data = JSON.parse(identifiers);
            return data;
        }
    } catch (e) {
        console.error(e);
    }
}

export function cleanupIdentifiers() {
    try {
        for (const [key, value] of Object.entries(localStorage)) {
            const isIdentifier = key.startsWith("request_id__");
            if (isIdentifier) {
                const { timestamp } = JSON.parse(value);
                if ((timeDifference(new Date(timestamp), new Date()) > LOCAL_STORAGE_TIMEOUT_MS)) {
                    localStorage.removeItem(key);
                }
            }
        }
    } catch (e) {
        console.error(e);
    }
}

export function normaliseDocumentId(id: string) {
    if (id.includes("web")) {
        return {
            isWeb: true,
            isFile: false,
            id: id,
        }
    }

    const fileId = getFileId(id);
    return {
        isWeb: false,
        isFile: true,
        id: fileId
    }
}

function getUserFlagKey(flag: UserFeatureFlag) {
    return `${USER_FEATURE_FLAG_PREFIX}_${flag}`;
}

export function checkUserFlag(flag: UserFeatureFlag) {
    try {
        const key = getUserFlagKey(flag);
        const result = localStorage.getItem(key);
        if (result === "true") {
            return true;
        }
        return false
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function setUserFlag(flag: UserFeatureFlag, isEnabled: boolean) {
    try {
        const key = getUserFlagKey(flag);
        localStorage.setItem(key, `${isEnabled}`);
    } catch (e) {
        console.error(e);
    }
}

// todo: move server side when we want to persist beyond single device
export function saveUserSettings(s: UserSettings) {
    try {
        const str = JSON.stringify(s);
        sessionStorage.setItem(USER_SETTINGS_KEY, str);
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function getUserSettings() {
    try {
        const value = sessionStorage.getItem(USER_SETTINGS_KEY);
        if (!value) return null;
        return JSON.parse(value);
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function getIconSrc(domain: string, size?: number) {
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=${size || 256}`
}

export function mapPlannerDocumentToSourceDocument(pd: ExpertModeDocument): SourceDocument {
    return {
        document_id: pd.id,
        title: pd.title,
        url: pd.url,
        text: pd.snippet, // todo: snippet or title?
    }
}

export function checkPathMatch(location: string, menuItemPath: string) {
    if (menuItemPath.toLowerCase().includes(location.toLocaleLowerCase())) {
        return true;
    }
    return false;
}

export function captialise(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getLocale() {
    return "en-GB";
}

export function scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
}

export function checkDesiaUser(user: ResAppUser | null) {
    if (!user) return false;
    try {
        const email = user.email;
        if (email.endsWith("@desia.ai")) {
            return true;
        }
        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function friendlyOrgName(orgId: string) {
    try {
        const [_, name] = orgId.split("__");
        return captialise(name);
    } catch (e) {
        console.error(e);
        return orgId;
    }
}

export function getCaretPosition(node: Node) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(node);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        return preCaretRange.toString().length;
    }
    return 0;
}

export function setCaretPosition(position: number, node: Node) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);

    let currentPos = 0;
    let found = false;

    const traverseNodes = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            const textLength = node.textContent?.length ?? 0;
            if (currentPos + textLength >= position) {
                range.setStart(node, position - currentPos);
                range.collapse(true);
                found = true;
            } else {
                currentPos += textLength;
            }
        } else {
            for (let i = 0; i < node.childNodes.length; i++) {
                traverseNodes(node.childNodes[i]);
                if (found) break;
            }
        }
    };

    traverseNodes(node);
    if (found) {
        selection?.removeAllRanges();
        selection?.addRange(range);
    }
}

// modified from this: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes: number) {
    if (bytes === 0 || bytes < 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const formattedValue = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

    return `${formattedValue} ${sizes[i]}`;
}