import "./Search.css";
import { useContext, useEffect, useRef, useState } from 'react';
import icon from "../assets/icons8-search.svg";
import filterIcon from "../assets/filter-svgrepo-com.svg";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DESIA_EVENT, ResAutocomplete, ResponseSearchQuery, WebSocketResponseWrapper } from '../types/types';
import { breakpoints } from '../constants';
import aiIcon from '../assets/ai-mi-algorithm-svgrepo-com.svg';
import { useWindowSize } from '../hooks/useWindowSize';
import { Tooltip } from "react-tooltip";
import { ErrorMessage } from "./ErrorMessage";
import { WebSocketContext } from "../contexts/WebSocketContext";
import { groupByFile } from "../utils/search";
import { SearchResult } from "./SearchResult";


export function Search() {
    const secureSocket = useContext(WebSocketContext);
    const socket = secureSocket.socket!;
    const [searchParams, setSearchParams] = useSearchParams();
    const [q, setQ] = useState(searchParams.get("q") || "");
    const [_, setAutocomplete] = useState<ResAutocomplete>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchResults, setSearchResults] = useState<WebSocketResponseWrapper<ResponseSearchQuery> | null>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const size = useWindowSize();


    function onQuery(e: React.ChangeEvent<HTMLInputElement>) {
        setQ(e.target.value);
    }

    function handleAutocomplete(res: any) {
        setAutocomplete(res);
    }
    function handleSearchResults(res: any) {
        setSearchResults(res);
        setLoading(false);
    }

    function search(query: string) {
        setLoading(true);
        socket.emit(DESIA_EVENT.SEARCH_QUERY, { query });
    }

    const preQuery = searchParams.get("q");

    useEffect(() => {
        if (preQuery) {
            setQ(preQuery);
            search(preQuery);
        }
    }, [preQuery]);

    useEffect(() => {
        socket.on(DESIA_EVENT.SEARCH_AUTOCOMPLETE, handleAutocomplete);
        socket.on(DESIA_EVENT.SEARCH_QUERY, handleSearchResults);

        function handleKeydown(event: KeyboardEvent) {
            if (event.key === "Enter" && inputRef.current?.value) {
                const next = new URLSearchParams();
                next.set("q", inputRef.current?.value);
                setSearchParams(next);
            }
        }
        addEventListener("keydown", handleKeydown);

        return () => {
            socket.on(DESIA_EVENT.SEARCH_AUTOCOMPLETE, handleAutocomplete);
            socket.on(DESIA_EVENT.SEARCH_QUERY, handleSearchResults);
            removeEventListener("keydown", handleKeydown);
        }
    }, []);

    const responsiveClasses = size.width < breakpoints.lg ? "block-override" : "";
    const dedupedResults = groupByFile(searchResults?.data || []);

    return (
        <div className={`search-page-container`}>
            <div className='search-page-inner'>
                <div>
                    <div className="spotlight-search-main-controls with-rounded-border">
                        <img src={icon} className='spotlight-search-main-controls-icon' />
                        <input
                            ref={inputRef}
                            tabIndex={1}
                            type='text'
                            className="spotlight-search-input"
                            placeholder="Search Desia..."
                            autoFocus={true}
                            onChange={onQuery}
                            value={q}
                        />
                        <button tabIndex={2} className="spotlight-search-filter-button"
                            data-tooltip-id="tooltip-search-filters"
                            data-tooltip-content="Coming soon: search filters"
                            data-tooltip-place="top"
                        >
                            <img src={filterIcon} className='spotlight-search-filter-button-icon' />
                        </button>
                    </div>
                </div>
                <Tooltip id="tooltip-search-filters" />

                {!loading && searchResults?.error && (
                    <div className={`search-results-container ${responsiveClasses}`}>
                        <ErrorMessage message={`${searchResults.error} (search:query)`} />
                    </div>
                )}

                {loading && (
                    <div className={`search-results-container ${responsiveClasses}`}>
                        <div className='search-results-main-wrapper'>
                            <div>
                                <div className='search-document-loading skeleton'>

                                </div>
                            </div>
                        </div>

                        <div className='search-results-filters-wrapper'>
                            <div className='search-filters-loading skeleton'>

                            </div>
                        </div>
                    </div>
                )}

                {!loading && searchResults && (
                    <>
                        <div className={`search-results-container ${responsiveClasses}`}>
                            <div className='search-results-main-wrapper'>
                                <div>
                                    <div className='answer-ask-desia' onClick={() => {
                                        const encodedQuestion = encodeURIComponent(preQuery || "");
                                        navigate(`/assistant/ask/?q=${encodedQuestion}&trigger=true&mode=simple`)
                                    }}>
                                        <div className='icon-container'>
                                            <img src={aiIcon} className='ai-icon' />
                                        </div>
                                        <span>Ask Desia: "<span className='ask-desia-query'>{preQuery}</span></span>"
                                    </div>
                                    {dedupedResults.map(d => {
                                        return (
                                            <SearchResult key={d.id} dedupedResult={d} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}