import { NotebookPen } from "lucide-react";
import { Button } from "../ui/button";
import { TypographyH3 } from "../ui/Typography";
import { ReportCard } from "./ReportCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { requestCreateReport, requestDeleteReport, requestReports } from "../DocGen/docGenSlice";
import { getTimestamp } from "@/utils/utils";
import { ResponseDocGenReport } from "@/types/types";
import { Skeleton } from "../ui/skeleton";
import { ErrorMessage } from "../ErrorMessage";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogTitle } from "../ui/dialog";

export interface MockReport {
    title: string
    description: string
}

// todo: implement pagination
export function ReportsPage({ reports, error, loading, createReportLoading, createReportError, handleCreateReport, handleDeleteReport }: {
    reports: ResponseDocGenReport[],
    loading: boolean,
    error: any,
    createReportLoading?: boolean,
    createReportError?: string | null,
    handleCreateReport: () => void,
    handleDeleteReport: (reportId: string) => void,
}) {
    return (
        <div className="flex flex-col gap-10">
            <div className="text-center">
                <TypographyH3>Reports</TypographyH3>
            </div>

            <div className="flex flex-col gap-6">
                <div className="flex w-fit ml-auto">
                    <Button disabled={createReportLoading} onClick={handleCreateReport}>
                        <div className="flex gap-2">
                            <NotebookPen className="h-6 w-6 stroke-[1.5px]" />
                            Create Report
                        </div>
                    </Button>
                </div>

                {createReportError && (
                    <ErrorMessage message="We failed to create a new report, try again shortly" />
                )}

                {!loading && error && (
                    <ErrorMessage message="We failed to list reports, try again shortly" />
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {loading && (
                        <>
                            <Skeleton className="w-full h-[340px]" />
                            <Skeleton className="w-full h-[340px]" />
                            <Skeleton className="w-full h-[340px]" />
                            <Skeleton className="w-full h-[340px]" />
                        </>
                    )}
                    {reports.map((r) => (
                        <ReportCard key={r.id} report={r} handleDelete={handleDeleteReport} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export function ReportsPageContainer() {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [deletedReport, setDeletedReport] = useState<ResponseDocGenReport | null>(null)

    const reports = useSelector((state: RootState) => state.docGen.reports);
    const createReport = useSelector((state: RootState) => state.docGen.createReport);
    const dispatch = useDispatch();

    const createReportHandler = () => {
        dispatch(requestCreateReport({
            requestId: "docgen:create_report",
            params: {},
            timestamp: getTimestamp(),
        }))
    }

    const deleteReportHandler = (reportId: string) => {
        const report = reports.data?.find((v) => v.id === reportId)

        if (!report) return
        setDeletedReport(report)
        setShowDeleteDialog(true)
    }

    const deleteReport = () => {
        if (!deletedReport) return

        dispatch(requestDeleteReport({
            requestId: "docgen:delete_report",
            params: {
                report_id: deletedReport.id,
            },
            timestamp: getTimestamp(),
        }))
    }

    useEffect(() => {
        dispatch(requestReports({
            requestId: "docgen:list_reports_20_0",
            params: {
                limit: 20,
                offset: 0,
            },
            timestamp: getTimestamp(),
        }));
    }, [])


    return (
        <>
            <ReportsPage
                reports={reports.data || []}
                loading={reports.loading}
                error={reports.error}
                createReportLoading={createReport.loading}
                createReportError={createReport.error}
                handleCreateReport={createReportHandler}
                handleDeleteReport={deleteReportHandler}
            />

            <Dialog open={showDeleteDialog} onOpenChange={(v) => setShowDeleteDialog(v)}>
                <DialogContent className="w-[504px]">
                    <DialogTitle className="max-w-[calc(504px-48px)]">{`You are about to delete report ${deletedReport?.title || 'Untitled'}`}</DialogTitle>
                    <DialogDescription>This action cannot be undone.</DialogDescription>
                    <DialogFooter className="mt-4">
                        <Button variant='secondary-destructive'
                            onClick={() => {
                                setShowDeleteDialog(false)
                                deleteReport()
                            }}>
                            Delete report
                        </Button>

                        <Button onClick={() => {
                            setShowDeleteDialog(false)
                            setDeletedReport(null)
                        }}>
                            Keep report
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}
