import { Citation, ResponseEntityExtraction, SourceDocument, SystemMessage } from "@/types/types";
import { AskPanelTool } from "./AskPanelTool";
import { FactCheck } from "./FactCheck";
import { Research } from "./Research";
import { OutputData } from "@editorjs/editorjs";

export function Picker({ reportId, data, componentType, extractedEntities, addToDocument, addToDocumentInline }: {
    reportId: string,
    data: OutputData,
    extractedEntities?: ResponseEntityExtraction,
    componentType: 'factCheck' | 'research' | 'ask' | null,
    addToDocument: (systemMessage: SystemMessage) => void,
    addToDocumentInline: (partialSystemMessage: { text: string, citations: Citation[], documents: SourceDocument[] }) => void,
}) {
    if (!componentType) return null;
    if (componentType === 'factCheck') {
        return <FactCheck data={data} extractedEntities={extractedEntities} addToDocumentInline={addToDocumentInline} />
    }
    if (componentType === 'research') {
        return <Research data={data} addToDocumentInline={addToDocumentInline} />
    }
    if (componentType === 'ask') {
        return <AskPanelTool reportId={reportId} data={data} addToDocument={addToDocument} />
    }
    return null;
}
