import { SourceDocumentId } from "../contexts/AssistantContext";
import { Citation } from "../types/types";
import { normaliseDocumentId } from "./utils";

// https://www.30secondsofcode.org/js/s/replace-last-occurrence/
const replaceLast = (str: string, pattern: string | RegExp, replacement: string): string => {
    const spaceCharacter = "‎";
    const match =
        typeof pattern === 'string'
            ? pattern
            : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
    if (!match) return str;
    const last = str.lastIndexOf(match);
    return last !== -1
        ? `${str.slice(0, last)}${spaceCharacter}${replacement}${spaceCharacter}${str.slice(last + match.length)}`
        : str;
};

function sanitizeTextForMarkdown(text: string) {
    // markdown-to-jsx struggles with rendering markdown bold text (double asterix) adjacent to components
    return text.replaceAll("**", "");
}

export function embedCitations(props: { text: string, citations: Citation[], highlightCitation: SourceDocumentId }): string {
    const { text, citations, highlightCitation } = props;
    try {
        const input = sanitizeTextForMarkdown(text);
        let pointer = text.length;
        let orderedCitations = citations.sort((a, b) => Number(a.start) - Number(b.start));
        if (!text) return text;
        if (!citations || citations.length === 0) return text;

        let mutableResult = input;
        for (let i = orderedCitations.length; i > 0; i--) {

            const citation = orderedCitations[i - 1];
            const pre = citation.text;
            const highlight = citation.document_ids.map(id => normaliseDocumentId(id).id).includes(normaliseDocumentId(highlightCitation).id);
            const post = `<Cite highlight={${highlight}} documentIds={[${citation.document_ids.map(x => `"${x}"`).join(",")}]}>${pre}</Cite>`;

            const left = mutableResult.substring(0, pointer);
            const right = mutableResult.substring(pointer, mutableResult.length);
            mutableResult = `${replaceLast(left, pre, post)}${right}`;
            pointer = citation.start;
        }
        return mutableResult;
    } catch (e) {
        console.error(e);
        return text;
    }
}

// todo: replace embedCitations with embedCitationsV2 once new design fully integrated
export function embedCitationsV2(props: { text: string, citations: Citation[], highlightCitation: SourceDocumentId, isComplete: boolean }): string {
    const { text, citations, highlightCitation } = props;
    try {
        const citationsCopy = [...citations];
        const input = sanitizeTextForMarkdown(text);
        let pointer = text.length;
        let orderedCitations = citationsCopy.sort((a, b) => Number(a.start) - Number(b.start));
        const prefix = `<span>`;
        const postfix = `</span>`;
        // show dot cursor iff answer is still streaming
        // n.b. citations are loaded after answer streamed
        const cursor = `<Cursor show={${props.isComplete === false && !citations.length}} />`;
        if (!text) return `${prefix}${cursor}${postfix}`;
        if (!citations || citations.length === 0) return `${prefix}${text}${cursor}${postfix}`;

        let mutableResult = input;
        for (let i = orderedCitations.length; i > 0; i--) {

            const citation = orderedCitations[i - 1];
            const pre = citation.text;
            const highlight = citation.document_ids.map(id => normaliseDocumentId(id).id).includes(normaliseDocumentId(highlightCitation).id);
            const post = `<Cite highlight={${highlight}} documentIds={[${citation.document_ids.map(x => `"${x}"`).join(",")}]}>${pre}</Cite>`;
            const left = mutableResult.substring(0, pointer);
            const right = mutableResult.substring(pointer, mutableResult.length);
            mutableResult = `${replaceLast(left, pre, post)}${right}`;
            pointer = citation.start;
        }
        return `${prefix}${mutableResult}${cursor}${postfix}`;
    } catch (e) {
        console.error(e);
        return text;
    }
}
