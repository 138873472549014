import { ChatSummary } from "@/types/types";
import { useNavigate } from "react-router";
import { WarnOnNavigate } from "../WarnOnNavigate";
import { useLocation } from "react-router-dom";
import { TypographyLabel } from "../ui/Typography";

export function RecentThreads({ threads }: { threads: ChatSummary[] }) {
    const navigate = useNavigate();
    const location = useLocation();

    const shownThreads = threads.slice(0, 3)

    const dynamicStyle = "flex flex-col gap-0 text-sm font-label text-system-body max-h-60 overflow-y-auto pl-3";
    if (shownThreads.length === 0) {
        return null;
    }
    return (
        <div className={dynamicStyle}
        >
            {shownThreads
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((t, idx) => {
                    const isOpen = location.pathname.includes(t.conversationId) || (t.requestId && location.search.includes(t.requestId));
                    return (
                        <WarnOnNavigate key={`thread-preview-${idx}`} to={`/assistant/conversation/${t.conversationId}`} component={
                            <div
                                key={`thread-preview-${idx}`}
                                className={`hover:text-system-primary hover:cursor-pointer ${idx !== shownThreads.length - 1 ? 'mb-4' : ''} ${isOpen ? "border-l border-system-border-regular pl-3" : ""}`}
                                onClick={() => {
                                    if (t.conversationId) {
                                        return navigate(`/assistant/conversation/${t.conversationId}`);
                                    }
                                    if (t.requestId) {
                                        return navigate(`/assistant/ask/new/?request_id=${t.requestId}`);
                                    }
                                    console.error(`[desia-web-app]: missing teaser link for summary ${JSON.stringify(t)}`);
                                }}
                            >
                                <TypographyLabel className="line-clamp-3">
                                    {t.query}
                                </TypographyLabel>

                            </div>
                        } />
                    )
                })}
            {/* todo: fixme, temp hack as previously navigation triggered on last item opening first conversation on page load */}
            <WarnOnNavigate to="/assistant/ask/" component={
                <div></div>
            } />
        </div>
    )
}
