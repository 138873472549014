import "./SearchResult.css";
import { DedupedSearchQueryItem, SearchQueryExtract } from "../types/types";
import documentIcon from '../assets/icons8-document_black.svg';
import { handleOpenLink, plural } from "../utils/utils";
import { ReactElement, useState } from "react";
import { getOverlayedHighlights } from "../utils/search";
import Markdown from "markdown-to-jsx";

interface Props {
    dedupedResult: DedupedSearchQueryItem
}

function getVisibleExtracts(extracts: SearchQueryExtract[], showAllExtracts: boolean) {
    if (showAllExtracts) {
        return extracts;
    }

    const [first] = extracts;
    return [first];
}

function Highlight(props: { children: ReactElement }) {
    return <span className="search-highlight">{props.children}</span>
}

function ShowMoreExtracts({ extracts, showAllExtracts, handleShowAllExtracts, handleHideExtracts }: {
    extracts: SearchQueryExtract[],
    showAllExtracts: boolean,
    handleShowAllExtracts: () => void,
    handleHideExtracts: () => void,
}) {
    
    const total = extracts.length;
    if (total === 1) { return <></> };

    const remaining = total - 1;

    if (showAllExtracts) {
        return (
            <button className="extract-toggle-button" onClick={() => { handleHideExtracts(); }}>Hide {remaining} {plural("extract", remaining)}</button>
        )
    }

    return (
        <button className="extract-toggle-button" onClick={() => { handleShowAllExtracts(); }}>Show {remaining} more {plural("extract", remaining)}</button>
    )
}

export function SearchResult({ dedupedResult: d }: Props) {
    const [showAllExtracts, setShowAllExtracts] = useState(false);
    const visibleExtracts = getVisibleExtracts(d.extracts, showAllExtracts);
    return (
        <div className='search-result-container fade-in-fast'>
            <div className='icon-container'>
                <img aria-label='upload file icon' src={documentIcon} className='document-icon' />
            </div>
            <div className='search-result-document'>
                <div className='search-result-title' onClick={() => {
                    handleOpenLink({
                        id: d.id,
                        url: d.url,
                        title: d.title,
                        window,
                    });
                }}>{d.title}</div>

                {visibleExtracts.map((extract, idx) => {
                    return (
                        <div key={`extract-${d.id}-${idx}`} className={`search-result-extract`}>
                            <Markdown options={{
                                overrides: {
                                    em: {
                                        component: Highlight
                                    },
                                },
                                wrapper: 'span',
                                forceBlock: true,
                            }}>
                                {getOverlayedHighlights(extract.text, extract.highlight?.passage_text || [])}
                            </Markdown>
                        </div>
                    )
                })}
                <ShowMoreExtracts
                    extracts={d.extracts}
                    showAllExtracts={showAllExtracts}
                    handleShowAllExtracts={() => { setShowAllExtracts(true); }}
                    handleHideExtracts={() => { setShowAllExtracts(false); }}
                />
            </div>
        </div>
    )
}
