import { responseEntityExtraction, responseSaveReport } from "@/components/DocGen/docGenSlice";
import { fetchUserAuthTicket, fetchUserProfile } from "@/features/userSlice"
import { DESIA_EVENT, ResponseChatStream, ResponseDocGenReport, WebSocketResponseWrapper } from "@/types/types"
import { SocketConnection } from "@/utils/SocketConnection"

// This pattern of integrating websocket events with redux is from https://www.taniarascia.com/websockets-in-redux/
// it provides a way to emit events and listen for responses from the server
// redux will then handle the actions (see docGenSlice.ts for the reducers) to update store state
// @ts-expect-error
export const socketIOMiddleware = (socket: SocketConnection) => (params) => (next) => action => {
    const { dispatch } = params;

    if (!socket.dispatch) {
        socket.dispatch = dispatch;
    }

    switch (action.type) {
        case "user/connect":
            dispatch(fetchUserAuthTicket());
            break;
        case "user/fetchAuthTicket/rejected":
            dispatch({ type: "user/reconnect" });
            break;
        case "user/fetchAuthTicket/fulfilled":
            socket.connect(action.payload);
            socket.on("connect", () => {
                dispatch({ type: "user/connected" });
            });
            break;
        case "user/connected":
            console.debug("[redux middleware] user connected");
            socket.on("disconnect", async () => {
                console.warn("[desia-web-app] websocket disconnected unexpectedly. Attempting to reconnect");
                dispatch({ type: "user/reconnect" });
            })
            // responses from the server caught by event listeners
            // note: binding on user/connected allows for reconnection / rebinding of event listeners
            socket.on(DESIA_EVENT.FACT_CHECK_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseFactCheck", payload: response });
            })
            socket.on(DESIA_EVENT.FACT_CHECK_V2_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseFactCheckV2", payload: response });
            })
            socket.on(DESIA_EVENT.RESEARCH_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseResearch", payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_ASK_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseAsk", payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_LIST_REPORTS_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseReports", payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_CREATE_REPORT_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseCreateReport", payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_DELETE_REPORT_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseDeleteReport", payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_GET_REPORT_RESPONSE, (response) => {
                dispatch({ type: "docgen/responseReportById", payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_UPDATE_REPORT_RESPONSE, (response) => {
                const data = response as WebSocketResponseWrapper<ResponseDocGenReport>;
                dispatch(responseSaveReport(data));
            })
            socket.on(DESIA_EVENT.DOCGEN_EXTRACT_ENTITIES_RESPONSE, (response) => {
                const data = response as WebSocketResponseWrapper<ResponseChatStream>;
                if (data.data?.is_finished == true) {
                    dispatch(responseEntityExtraction(data));
                }
            })
            dispatch(fetchUserProfile());
            break;
        // requests to the server by way of websocket events
        case "docgen/requestFactCheck":
            socket.emit(DESIA_EVENT.FACT_CHECK_REQUEST, action.payload);
            break;
        case "docgen/requestFactCheckV2":
            socket.emit(DESIA_EVENT.FACT_CHECK_V2_REQUEST, action.payload);
            break;
        case "docgen/requestResearch":
            socket.emit(DESIA_EVENT.RESEARCH_REQUEST, action.payload);
            break;
        case "docgen/newAsk":
            socket.emit(DESIA_EVENT.DOCGEN_ASK_REQUEST, action.payload);
            break;
        case "docgen/followUpAsk":
            socket.emit(DESIA_EVENT.DOCGEN_ASK_REQUEST, action.payload);
            break;
        case "docgen/requestReports":
            socket.emit(DESIA_EVENT.DOCGEN_LIST_REPORTS_REQUEST, action.payload);
            break;
        case "docgen/requestCreateReport":
            socket.emit(DESIA_EVENT.DOCGEN_CREATE_REPORT_REQUEST, action.payload);
            break;
        case "docgen/requestDeleteReport":
            socket.emit(DESIA_EVENT.DOCGEN_DELETE_REPORT_REQUEST, action.payload);
            break;
        case "docgen/requestReportById":
            socket.emit(DESIA_EVENT.DOCGEN_GET_REPORT_REQUEST, action.payload);
            break;
        case "docgen/requestSaveReport":
            socket.emit(DESIA_EVENT.DOCGEN_UPDATE_REPORT_REQUEST, action.payload);
            break;
        case "docgen/requestEntityExtraction":
            socket.emit(DESIA_EVENT.DOCGEN_EXTRACT_ENTITIES_REQUEST, action.payload);
            break;
        default:
            break;
    }

    if (action.type !== "docgen/setToolCursor") {
        console.debug("[redux middleware] action:", action);
    }
    return next(action)
}
