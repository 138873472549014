import { CustomAlertProps } from "@/components/CustomAlert";
import { ReactNode, createContext, useState } from "react";

interface IToastContext {
    isToastShown: boolean
    shownToast: CustomAlertProps | null,
    showToast: (alert: CustomAlertProps) => void
    closeToast: () => void
}
interface IProviderProps {
    children: ReactNode;
}
const initialContextValue = {
    isToastShown: false,
    shownToast: {},
    showToast: () => { },
    closeToast: () => { },
} satisfies IToastContext

export const ToastContext = createContext<IToastContext>(initialContextValue);

function ToastProvider({ children }: IProviderProps) {
    const [isToastShown, setIsToastShown] = useState<boolean>(false);
    const [shownToast, setShownToast] = useState<CustomAlertProps | null>(null);

    function showToast(alert: CustomAlertProps) {
        setShownToast(alert)

        setTimeout(() => {
            setIsToastShown(true)
        }, 200)

        if (!alert.dismissable) {
            setTimeout(() => {
                closeToast()
            }, 2000)
        }
    }

    function closeToast() {
        setIsToastShown(false)

        setTimeout(() => {
            setShownToast(null)
        }, 200)
    }

    return (
        <ToastContext.Provider value={{
            isToastShown,
            shownToast,
            showToast,
            closeToast
        }}>
            {children}
        </ToastContext.Provider>
    )
}

export { ToastProvider };