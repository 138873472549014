import { ReactNode, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { TypographyBody } from "./ui/Typography";
import { Button } from "./ui/button";
import { AssistantStoreContext } from "@/contexts/AssistantContext";
import { notEmpty } from "@/utils/utils";
import { FF_WARN_ON_NAVIGATE } from "@/constants";

export function WarnOnNavigate({ to, component }: { to: string, component: ReactNode }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { store } = useContext(AssistantStoreContext);

    const activeConversationsId: string[] = store.liveConversations
        .filter(c => {
            const lastMessage = c.conversation[c.conversation.length - 1];
            if (lastMessage.role === "system") {
                return lastMessage.data.isFinished !== true;
            }
            return false;
        })
        .map(c => {
            return [c.conversationId, ...(c.requestIds || [])];
        })
        .flat()
        .filter(notEmpty)

    const pathname = location.pathname;
    const locationSearch = location.search;
    const isCurrentlyViewingConversation = (pathname.includes("/assistant/ask/new/") || pathname.includes("/assistant/conversation/"));
    const conversationIsActive = activeConversationsId.some(id => pathname.includes(id) || locationSearch.includes(id));
    const showWarning = isCurrentlyViewingConversation && conversationIsActive && (FF_WARN_ON_NAVIGATE === "true");


    function handleNavigate() {
        navigate(to);
    }

    if (!showWarning) {
        return component;
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <div className="hover:cursor-pointer">
                    <div className="pointer-events-none">
                        {component}
                    </div>
                </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>You are about to leave this page</DialogTitle>
                    <DialogDescription className="py-6">
                        <TypographyBody>
                            Leaving the page will discard the current conversation. Are you sure you want to leave?
                        </TypographyBody>
                    </DialogDescription>
                    <DialogFooter>
                        <DialogClose>
                            <Button variant="secondary" className="w-full" onClick={handleNavigate}>Leave and discard last question</Button>
                        </DialogClose>
                        <DialogClose>
                            <Button className="w-full">
                                Stay on page
                            </Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}
