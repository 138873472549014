import { Citation, SourceDocument } from "../../types/types";
import { Skeleton } from "@/components/ui/skeleton";
import { DotIcon } from "lucide-react";
import { embedCitationsV2 } from "../../utils/embedCitations";
import Markdown from "markdown-to-jsx";
import { ResponsiveContainer } from "./ResponsiveContainer";
import { SourceDocumentId } from "@/contexts/AssistantContext";
import { Dialog, DialogContent, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { DialogHeader } from "../ui/dialog";
import { Sources } from "./Sources";
import { useState } from "react";

type Props = {
    isLoading: boolean;
    text: string;
    isComplete: boolean;
    documents: SourceDocument[];
    citations: Citation[];
    compact?: boolean;
}

interface AnswerBlockProps {
    text: string;
    citations: Citation[];
    isComplete: boolean;
    compact?: boolean;
    onSelectCitation: (s: SourceDocumentId[]) => void
}

interface CitedTextProps {
    text: string;
    citations: Citation[];
    isComplete: boolean;
    compact?: boolean;
    onSelectCitation: (s: SourceDocumentId[]) => void
}

interface CursorProps {
    show: boolean;
}

function LoadingBlocks() {
    return (
        <ResponsiveContainer>
            <Skeleton className="w-4/6 h-[20px] my-2" />
            <Skeleton className="w-full h-[20px] my-2" />
            <Skeleton className="w-full h-[20px] my-2" />
            <Skeleton className="w-5/6 h-[20px] my-2" />
        </ResponsiveContainer>
    )
}

function CitedText({ text, citations, isComplete, compact, onSelectCitation }: CitedTextProps) {
    const embeddedCitations = embedCitationsV2({ text, citations, highlightCitation: "", isComplete });

    function Cite({ id, children, documentIds, highlight }: { id: string, children: string, documentIds: string, highlight: boolean, onSelectCitation: (s: SourceDocumentId[]) => void }) {
        try {
            const classes = highlight ? "" : "!font-medium hover:cursor-pointer text-black z-[0]";
            const containerClasses = highlight ? "" : "citation-background"
            const parsedDocumentIds = JSON.parse(documentIds || "[]");
            return (
                <span className={containerClasses}>
                    <span
                        className={`relative ${compact ? 'font-citation-mini' : 'font-citation'} ${classes}`}
                        onClick={() => {
                            onSelectCitation(parsedDocumentIds);
                        }}
                    >{children}</span>
                </span>
            )
        } catch (e) {
            console.error(`[desia-web-app] error rendering Cite with props: ${JSON.stringify({ id, documentIds })}`);
            return children;
        }
    }

    return (
        <Markdown options={{
            overrides: {
                Cite: {
                    component: Cite
                },
                Cursor: {
                    component: Cursor
                }
            },
            wrapper: 'span',
            forceBlock: true,
        }}
        >
            {embeddedCitations}
        </Markdown>
    )
}

function Cursor({ show }: CursorProps) {
    if (!show) return null;
    return <DotIcon className="w-6 h-6 text-system-body inline" />;
}

function AnswerBlock({ text, citations, isComplete, compact, onSelectCitation }: AnswerBlockProps) {
    return (
        <ResponsiveContainer>
            <div className={`text-system-body leading-7 ${compact ? "font-label" : "font-body"}`}>
                <span className="">
                    <CitedText
                        text={text}
                        citations={citations}
                        isComplete={isComplete}
                        onSelectCitation={onSelectCitation}
                        compact={compact}
                    />
                </span>
            </div>
        </ResponsiveContainer>
    )
}

function CitationSources({ documents, onClose }: { documents: SourceDocument[]; onClose: () => void; }) {
    return (
        <Dialog open={documents.length > 0} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Sources</DialogTitle>
                    <DialogDescription>
                    </DialogDescription>
                </DialogHeader>
                <div className="grid grid-cols-1 h-[400px]">
                    <Sources documents={documents} />
                </div>
            </DialogContent>
        </Dialog>
    )
}

function getUniqueDocumentIds(inputIds: string[]) {
    try {
        const documentIds = inputIds.map(d => {
            if (d.startsWith("web-search")) {
                return d;
            }
            const [id] = d.split("_");
            return id;
        })
        const uniqueDocumentIds = Array.from(new Set(documentIds));
        return uniqueDocumentIds;
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function FinalAnswer({ isLoading, isComplete, text, citations, documents, compact }: Props) {
    const [selectedCitationIds, setSelectedCitationIds] = useState<string[]>([]);

    function handleSelectCitation(s: string[]) {
        try {
            setSelectedCitationIds(getUniqueDocumentIds(s));
        } catch (e) {
            console.error(`[desia-web-app] error selecting citation with ids: ${s}`);
        }
    }

    function hideCitationSources() {
        setSelectedCitationIds([]);
    }
    const citationDocuments = documents.filter(d => {
        try {
            let id = "";
            if (d.document_id.startsWith("web-search")) {
                id = d.document_id;
            } else {
                id = d.document_id.split("_")[0];
            }

            return selectedCitationIds.includes(id);
        } catch (e) {
            console.error(e);
            return false;
        }
    });

    if (isLoading) {
        return <LoadingBlocks />
    }
    return (
        <>
            <CitationSources documents={citationDocuments} onClose={hideCitationSources} />
            <AnswerBlock
                text={text}
                citations={citations}
                isComplete={isComplete}
                onSelectCitation={handleSelectCitation}
                compact={compact}
            />
        </>

    )
}
