import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestEntityExtraction, requestReportById, requestSaveReport, updateLocalReportData, updateLocalReportTitle } from "../DocGen/docGenSlice";
import { getTimestamp } from "@/utils/utils";
import { ReportWriter } from "../DocGen/ReportWriter";
import { OutputData } from "@editorjs/editorjs";
import { RequestDocGenUpdateReport, WebSocketRequestWrapper } from "@/types/types";
import { convertReportToString } from "@/utils/docgen";

export function ReportWriterPage() {
    const { report_id } = useParams();
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();

    const reportId = report_id || "";

    const editableReport = useSelector((state: RootState) => state.docGen.editReportById[reportId]);

    const title = editableReport?.client?.title || "";
    const clientReport = editableReport?.client?.report;

    function handleSave() {
        const timestamp = getTimestamp();
        const request: WebSocketRequestWrapper<RequestDocGenUpdateReport> = {
            requestId: reportId,
            timestamp,
            params: {
                report_id: reportId,
                title,
                content: JSON.stringify(clientReport)
            }
        }
        dispatch(requestSaveReport(request));
    }

    function handleExtract() {
        dispatch(requestEntityExtraction({
            requestId: reportId,
            timestamp: getTimestamp(),
            params: {
                reportId: reportId,
                content: convertReportToString(clientReport, title)
            }
        }))
    }

    useEffect(() => {
        const shouldFetchReport = (!editableReport || !editableReport.server.loading) && Boolean(reportId);
        if (shouldFetchReport) {
            dispatch(requestReportById({
                requestId: reportId,
                timestamp: getTimestamp(),
                params: {
                    report_id: reportId
                }
            }))
        }
    }, [])

    // hack to allow editor to load blocks
    useEffect(() => {
        if (!ready && clientReport) {
            setTimeout(() => {
                setReady(true);
            }, 1_000);
        }
    }, [clientReport])

    if (!ready) {
        return null;
    }

    return (
        <ReportWriter
            key={`reportwriter_${reportId}`}
            reportId={reportId}
            title={title}
            report={clientReport}
            extractedEntities={editableReport?.client.extractedEntities}
            handleTitleChange={(title: string) => {
                dispatch(updateLocalReportTitle({ reportId: reportId, title }));
            }}
            handleReportChange={(data: OutputData) => {
                dispatch(updateLocalReportData({ reportId: reportId, data }));
            }}
            handleSave={handleSave}
            handleExtract={handleExtract}
        />
    )
}
