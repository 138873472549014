import { Clock } from "lucide-react"
import { Button } from "../ui/button"
import { Card } from "../ui/card"
import { TypographyBody, TypographyH4, TypographyLabel } from "../ui/Typography"
import { ResponseDocGenReport } from "@/types/types"
import { formatRelative } from 'date-fns'
import { useNavigate } from "react-router-dom"
import { OutputBlockData } from "@editorjs/editorjs"
import Markdown from "markdown-to-jsx"

function renderDescriptionFromReport(report: ResponseDocGenReport) {
    try {
        if (!report.content) {
            return <span className="text-muted-foreground">Open report to start writing</span>
        }
        const blocks: OutputBlockData[] = JSON.parse(report.content).blocks.filter((_: any, idx: number) => idx < 3);
        return (
            <div className="max-h-[125px] overflow-hidden">
                {blocks.map(b => {
                    return <Markdown className="mb-2" key={b.id}>{b.data?.text + " \n"}</Markdown>
                })}
            </div>
        )
    } catch (e) {
        console.error(e);
        return "Open report";
    }
}

export const ReportCard = ({ report, handleDelete }: { report: ResponseDocGenReport, handleDelete: (id: string) => void }) => {
    const updatedAt = `Last updated ${formatRelative(new Date(report.updated_at), new Date())}`;
    const navigate = useNavigate();
    return (
        <Card className="p-6 shadow-none hover:cursor-pointer hover:border-black" onClick={() => {
            navigate(`/report/${report.id}`)
        }}>
            <div className="flex flex-col gap-6 h-full">
                <div className="flex flex-col gap-3">
                    <div className="flex gap-2 items-center">
                        <Clock className="h-4 w-4 shrink-0 stroke-[1.5px] stroke-system-placeholder" />
                        <TypographyLabel className="text-system-body">
                            {updatedAt}
                        </TypographyLabel>
                    </div>
                    <TypographyH4 className="line-clamp-1">
                        {report.title || <span className="text-foreground">Untitled</span>}
                    </TypographyH4>
                </div>
                <TypographyBody className="text-system-body line-clamp-4 flex-grow">
                    {renderDescriptionFromReport(report)}
                </TypographyBody>

                <div className="flex justify-end">
                    <Button variant='tertiary-destructive' onClick={(evt) => {
                        handleDelete(report.id);
                        evt.preventDefault();
                        evt.stopPropagation();
                    }}>
                        Delete report
                    </Button>
                </div>
            </div>
        </Card>
    )
}