import "./AskTextBox.css";
import playIcon from "../assets/play-svgrepo-com.svg";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { ExtendedOptions } from "./Assistant/ExtendedOptions";

interface Props {
    placeholder: string;
    canSubmit: boolean;
    status?: string;
    handleAsk: (question: string) => void;
    initialQuestion?: string | null;
}

export function AskTextbox({ handleAsk, canSubmit, placeholder, status, initialQuestion }: Props) {
    const [question, setQuestion] = useState(initialQuestion || "");
    const ready = canSubmit && question;

    return (
        <div className="asktextbox-extended-container">
            <div className='asktextbox-container-wrapper'>
                <div className='asktextbox-container'>
                    <textarea
                        placeholder={placeholder}
                        className='asktextbox-textarea'
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.shiftKey !== true) {
                                e.preventDefault();

                                if (ready) {
                                    handleAsk(question);
                                    setQuestion("");
                                }
                            }
                        }}
                        onChange={e => {
                            setQuestion(e.target.value)
                        }}
                        value={question}
                        autoFocus
                    />
                    <div className='asktextbox-controls'>
                        <div className='asktextbox-controls-left'>
                            {!canSubmit && status && (
                                <div className="asktextbox-status-container">
                                    <div className="pulsating-circle" />
                                    <span>
                                        {status}
                                    </span>
                                </div>
                            )}
                        </div>
                        <button
                            aria-label='submit query'
                            className={`asktextbox-button asktextbox-button-primary ${ready ? "" : "asktextbox-button-loading"}`}
                            disabled={!ready}
                            onClick={() => {
                                handleAsk(question);
                                setQuestion("");
                            }}
                            data-tooltip-id="tooltip-asktextbox-busy"
                            data-tooltip-content={`${canSubmit ? "" : "Please wait until Assistant has finished responding"}`}
                            data-tooltip-place="top"
                        >
                            <img aria-label='play icon' src={playIcon} className='asktextbox-icon-play' />
                        </button>
                    </div>
                </div>
                <Tooltip id="tooltip-asktextbox-busy" />
            </div>
            <ExtendedOptions />
        </div>
    )
}
