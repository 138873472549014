import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { TypographyBody } from "../ui/Typography"
import { useState } from "react"
import { DialogClose } from "@radix-ui/react-dialog"
import { CustomAlert } from "../CustomAlert"

export function FileUpload({ handleUploadFile }: { handleUploadFile: (file: File) => Promise<void> }) {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.currentTarget.files) return;
    const [file] = e.currentTarget.files;
    if (!file) return;
    setSelectedFile(file);
  }

  function uploadFile() {
    if (!selectedFile) return;
    handleUploadFile(selectedFile)
      .finally(() => {
        setSelectedFile(null);
        setShowDialog(false);
      })
  }

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogTrigger asChild>
        <div>
          <Button variant={"secondary"}>
            Upload file
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[480px] overflow-hidden">
        <DialogHeader>
          <DialogTitle>Upload files</DialogTitle>
          <DialogDescription>
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-10 my-4">
          <div className="flex flex-col gap-4">
            <label htmlFor="file-upload">
              <Button variant='secondary' className="pointer-events-none cursor-pointer">
                Add File
              </Button>
            </label>
            <input id="file-upload" type="file" name="file" onChange={handleFileChange} className="hidden-file-input" />

            <TypographyBody className="text-system-body sm:max-w-[calc(480px-48px)] overflow-hidden text-ellipsis">{selectedFile?.name || "No file added yet"}</TypographyBody>
          </div>
          <div>
            <CustomAlert variant="info" description="Uploaded files will be accessible by anyone within your company" />
          </div>
        </div>
        <DialogFooter className="flex flex-row justify-end gap-2 sm:gap-[unset]">
          <DialogClose>
            <Button variant={"secondary"}>Cancel</Button>
          </DialogClose>
          <Button type="submit" onClick={uploadFile} disabled={!selectedFile}>Upload file</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
