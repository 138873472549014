import { CustomAlert } from './CustomAlert'

export function ErrorMessage(props: { message?: string, className?: string }) {
    const errMessage = props.message || "Something went wrong. Wait and try again";
    return (
        <CustomAlert
            variant="error"
            description={errMessage}
        />
    )
}
