import "./ChatBox.css";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { useEffect, useRef, useState } from "react";
import { AssistantAskMode } from "../../types/types";
import { SourceSelector } from "./SourceSelector";
import { StreamStatus } from "@/contexts/AssistantContext";
import { scrollToBottom } from "@/utils/utils";
import ArrowRight from "@/assets/ArrowRight";

export function ExpertModeSwitch({ handleCheckedChange, checked, }: { handleCheckedChange: () => void; checked: boolean }) {
    return (
        <Switch id="airplane-mode" onCheckedChange={handleCheckedChange} checked={checked} label="Expert mode" containerClassName="text-system-body" />
    )
}

export type ChatBoxProps = {
    expertModeAvailable: boolean;
    handleSubmit: (args: { message: string, mode: AssistantAskMode }) => void;
    initialMessage?: string;
    additionalControls?: boolean;
    status: StreamStatus;
    isConversation: boolean;
    showBorder: boolean
}
export function ChatBox({ expertModeAvailable, handleSubmit, initialMessage, additionalControls, status, isConversation, showBorder }: ChatBoxProps) {
    const [message, setMessage] = useState(initialMessage || "");
    const [expertMode, setExpertMode] = useState(false);

    const mode: AssistantAskMode = expertMode ? 'expert' : 'simple';
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    function sendMessage() {
        handleSubmit({ message, mode });
        scrollToBottom();
        setMessage("");
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    }, [message])

    const containerClass = `${isConversation ? `p-6 pb-8 bg-system-surface sm:rounded-lg sm:border-x border-t-[1.5px] ${showBorder ? 'border-border-gradient' : 'border-transparent bg-transparent'}  flex` : ""} max-w-[720px] mx-auto w-full`

    return (
        <div className={containerClass}>
            <div className="chatbox-container block sm:flex p-2 w-full flex-grow items-center">
                <div className="flex flex-1 mb-4 sm:mb-0 max-h-[320px]">
                    <textarea className="max-h-[320px] h-fit font-body placeholder:!text-system-placeholder !resize-none"
                        ref={textareaRef}
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.shiftKey !== true) {
                                e.preventDefault();

                                // todo: handle processing states
                                if (status === 'Ready') {
                                    sendMessage();
                                }
                            }
                        }}
                        placeholder="What do you need to know?"
                    />
                </div>
                <div className="flex justify-end items-center gap-8 max-h-12 mt-auto">
                    {expertModeAvailable && (
                        <div>
                            <ExpertModeSwitch checked={expertMode} handleCheckedChange={() => {
                                setExpertMode(!expertMode);
                            }} />
                        </div>
                    )}
                    <div>
                        <Button
                            onClick={sendMessage}
                            disabled={status !== 'Ready'}
                        >
                            <div className="flex gap-2">
                                Ask
                                <ArrowRight className="h-6 w-6 shrink-0 stroke-system-secondary" />
                            </div>
                        </Button>
                    </div>
                </div>

            </div>
            {additionalControls && mode === 'simple' && (
                <SourceSelector />
            )}
        </div>
    )
}
