import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { TypographyBody } from "../ui/Typography";

export function BackToResourcesLink() {
    const navigate = useNavigate();
    return (
        <div>
            <Button variant={"tertiary"} size={"default"} onClick={() => {
                navigate("/resources");
            }}>
                <ChevronLeft className="size-6 stroke-[1.5px]" />
                <TypographyBody>
                    All Documents
                </TypographyBody>
            </Button>
        </div>
    )
}
