import { Input } from "../ui/input";
import { useState } from "react";

export function SearchControl({ handleSearch, initialValue }: { handleSearch: (searchQuery: string) => void, initialValue?: string | null }) {
    const [searchQuery, setSearchQuery] = useState<string>(initialValue ?? "");

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            handleSearch(searchQuery);
        }
    }

    return (
        <div
            className="flex items-center justify-center"
        >
            <div className="flex items-center relative">
                <Input
                    onKeyDown={handleKeyDown}
                    placeholder="Search through documents"
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    className="w-[320px] px-10"
                    isSearch={true}
                    isCloseVisible={!!searchQuery}
                    onCloseClick={() => setSearchQuery('')}
                />
            </div>
        </div>
    )
}
