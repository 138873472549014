import { configureStore } from '@reduxjs/toolkit';
import docGenReducer from '../components/DocGen/docGenSlice';
import userReducer from '../features/userSlice';
import { socketIOMiddleware } from './middleware';
import { SocketConnection } from '@/utils/SocketConnection';


export const store = configureStore({
  reducer: {
    docGen: docGenReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketIOMiddleware(new SocketConnection())),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
