import './global.css'
import './index.css'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { UserProvider } from './contexts/UserContext.tsx';
import { DocumentStoreProvider } from './contexts/DocumentContext.tsx';
import { Login } from './components/Login.tsx';
import { Profile } from './components/Profile.tsx';
import { Layout } from './components/Layout.tsx';
import { Search } from './components/Search.tsx';
import { DocumentListContainer } from './components/DocumentList.tsx';
import { IsAuthenticated } from './components/IsAuthenticated.tsx';
import { ThreadsContainer } from './components/Threads.tsx';
import { ErrorFallback } from './components/ErrorFallback.tsx';
import { WebSocketProvider } from './contexts/WebSocketContext.tsx';
import { initializeSentry } from './services/sentry.ts';
import { AssistantStoreProvider } from './contexts/AssistantContext.tsx';
import { NewConversation } from './components/Assistant/NewConversation.tsx';
import { ExistingConversation } from './components/Assistant/ExistingConversation.tsx';
import { Ask } from './components/Assistant/Ask.tsx';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext.tsx';
import { Settings } from './components/Settings.tsx';
import { ResourceSearchPageContainer } from './components/Resources/ResourceSearchPage.tsx';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { ConnectionProvider } from './contexts/ConnectionContext.tsx';
import { LayoutProvider } from './contexts/LayoutContext.tsx';
import { ReportsPageContainer } from './components/Reports/ReportsPage.tsx';
import { ReportWriterPage } from './components/Reports/ReportWriterPage.tsx';
import { ToastProvider } from './contexts/ToastContext.tsx';

initializeSentry();


const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/",
    element: <Layout component={<IsAuthenticated><Login /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/search",
    element: <Layout component={<IsAuthenticated><Search /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/desk",
    element: <Layout component={<IsAuthenticated><></></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/resources",
    element: <Layout component={<IsAuthenticated><DocumentListContainer /></IsAuthenticated>} tall={true} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/resources/search",
    element: <Layout component={<IsAuthenticated><ResourceSearchPageContainer /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/reports",
    element: <Layout component={<IsAuthenticated><ReportsPageContainer /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/profile",
    element: <Layout component={<IsAuthenticated><Profile /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/settings",
    element: <Layout component={<IsAuthenticated><Settings /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/threads",
    element: <Layout component={<IsAuthenticated><ThreadsContainer /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/assistant/ask/",
    element: <Layout component={<IsAuthenticated><Ask /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/assistant/ask/new/",
    element: <Layout component={<IsAuthenticated><NewConversation /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/assistant/conversation/:conversation_id",
    element: <Layout component={<IsAuthenticated><ExistingConversation /></IsAuthenticated>} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
  {
    path: "/report/:report_id",
    element: <Layout component={<IsAuthenticated><ReportWriterPage /></IsAuthenticated>} tall={true} scrollable={false} />,
    errorElement: <Layout component={<ErrorFallback />} />
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConnectionProvider>
      <WebSocketProvider>
        <UserProvider>
          <FeatureFlagProvider>
            <DocumentStoreProvider>
              <AssistantStoreProvider>
                <LayoutProvider>
                  <ToastProvider>
                    <RouterProvider router={router} />
                  </ToastProvider>
                </LayoutProvider>
              </AssistantStoreProvider>
            </DocumentStoreProvider>
          </FeatureFlagProvider>
        </UserProvider>
      </WebSocketProvider>
    </ConnectionProvider>
  </Provider>
  // </React.StrictMode>,
)
