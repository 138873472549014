import { ReactNode, createContext, useEffect, useState } from "react";
import { DEBUG_MODE, WEB_SERVER_ENDPOINT } from "../constants";
import { Socket, io } from "socket.io-client";
import { handleLogin } from "../utils/utils";
import { ErrorMessage } from "../components/ErrorMessage";
import wait from "wait";

type WebSocketContext = {
    socket: Socket | null,
    status: 'connecting' | 'error' | 'connected' | 'reconnecting' | 'disconnected',
}

export const WebSocketContext = createContext<WebSocketContext>({ socket: null, status: 'connecting' });

function WebSocketProvider(props: { children: ReactNode }) {
    const [context, setContext] = useState<WebSocketContext>({ socket: null, status: 'connecting' });

    const handleConnected = () => {
        setContext((prev) => {
            return {
                socket: prev.socket,
                status: 'connected'
            }
        })
    }

    async function connect() {
        try {
            const response = await fetch(`${WEB_SERVER_ENDPOINT}/user/ws-ticket`, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            if (response.status === 403) {
                return handleLogin();
            }
            const { ticket } = await response.json();
            securelyConnect(ticket);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    // todo: surface reconnection message to user
    async function reconnect() {
        console.info(`[desia-web-app]: reconnecting`);
        let attempt = 10;
        while (['connecting', 'reconnecting'].includes(context.status) && attempt > 0) {
            console.info(`[desia-web-app]: attempt ${attempt}`);
            await wait(5_000); // todo: consider exp. backoff
            const connected = await connect();
            if (connected) {
                console.info(`[desia-web-app]: reconnection successful`);
                return true;
            }
            attempt = attempt - 1;
        }

        setContext(prev => {
            return {
                ...prev,
                status: 'disconnected'
            }
        });
        return false;
    }

    const securelyConnect = (ticket: string) => {
        const socket = io(WEB_SERVER_ENDPOINT, {
            path: '/ws',
            auth: {
                ticket
            },
            reconnection: false,
            forceNew: true,

        });
        socket.on('connect', handleConnected);
        socket.on("disconnect", (...args) => {
            console.error("websocket disconnected unexpectedly. Attempting to reconnect.", args);
            reconnect();
        })
        setContext(prev => {
            return {
                status: prev.status,
                socket,
            }
        });
        if (DEBUG_MODE) {
            console.info('desia running in debug mode');
            socket.onAnyOutgoing((event, response) => {
                console.info(`
event: ${event}
request:
${JSON.stringify(response, null, 2)}`);
            });
            socket.onAny((event, response) => {
                console.info(`
event: ${event}
response:
${JSON.stringify(response, null, 2)}`);
            });
        }
    }

    useEffect(() => {
        connect();
    }, []);

    if (context.status === 'connecting') {
        return <></>
    }

    if (context.status === 'error') {
        return <ErrorMessage message="We failed to connect you to our server. Our engineers have been informed. Refresh your page to reconnect." className="py-4" />;
    }

    if (context.status === 'disconnected') {
        return <ErrorMessage message="You've been disconnected from our server. Please refresh the page to reconnect." className="p-4" />;
    }

    return (
        <WebSocketContext.Provider value={context}>
            {props.children}
        </WebSocketContext.Provider>
    )
}

export {
    WebSocketProvider
}
