import { useContext, useEffect, useState } from "react";
import { QueryState, WebSocketRequest, WebSocketResponse } from "../types/types";
import { DEFAULT_CACHE_TIMEOUT_MS } from "../constants";
import { WebSocketContext } from "../contexts/WebSocketContext";

interface Props {
    event: string; // todo: enum?
    request: WebSocketRequest;
    options?: {
        cacheTimeoutMs?: number;
        getQuery?: (id: string) => QueryState | undefined;
        setQuery?: (query: QueryState) => void;
        callback?: (res: WebSocketResponse) => void;
        manual?: boolean;
    }
}
// todo: store cache keys / expiry info
const initialState: QueryState = {
    error: null,
    data: null,
    loading: false,
    request: {
        requestId: "",
        timestamp: 0,
        params: {}
    },
    event: "",
    timestamps: {
        request: null,
        response: null,
    },
}

function getShouldRequery(params: { timeoutMs: number, lastResponse: number | null, manual: boolean }) {
    const { timeoutMs, lastResponse, manual } = params;
    if (manual) return false;
    if (!lastResponse) return true;

    const now = (new Date()).getTime();
    const diff = now - lastResponse;
    if (diff > timeoutMs) {
        return true;
    }
    return false;
}

export function useSocketQuery({ event, request, options = { cacheTimeoutMs: 1_000 * 60 * 5, manual: false } }: Props) {
    const secureSocket = useContext(WebSocketContext);
    const socket = secureSocket.socket!;
    
    const [state, setState] = useState<QueryState>(() => {
        return {
            ...initialState,
            event,
            requestId: request.requestId,
            request,
            timestamps: {
                request: (new Date()).getTime(),
                response: null
            }
        }
    });

    function handleResponse(res: WebSocketResponse) {
        const next: QueryState = {
            event: state.event,
            request: state.request,
            loading: res.loading,
            data: res.data,
            error: res.error,
            timestamps: {
                request: state.timestamps.request,
                response: res.timestamp
            }
        }
        setState({
            ...next
        });
        if (options.callback) {
            options.callback(res);
        }
    }

    function execute(params?: { event: string; request: WebSocketRequest; }) {
        const cEvent = params?.event || event;
        const cRequest = params?.request || request;

        setState({
            loading: true,
            event: cEvent,
            request: cRequest,
            data: null,
            error: null,
            timestamps: {
                request: (new Date()).getTime(),
                response: null,
            }
        });
        socket.emit(cEvent, cRequest);
    }

    function executeQuery(params: { event: string; request: WebSocketRequest; }) {
        execute({
            event: params.event,
            request: params.request
        });
    }

    useEffect(() => {
        // todo: cleanup skip criteria
        let skip = false;
        
        if (options.getQuery) {
            const query = options.getQuery(request.requestId);
            if (query) {
                skip = true;
                if (query.timestamps.request) {
                    const shouldRequery = getShouldRequery({
                        timeoutMs: options.cacheTimeoutMs || DEFAULT_CACHE_TIMEOUT_MS,
                        lastResponse: query.timestamps.response,
                        manual: options.manual || true
                    })
                    if (shouldRequery) {
                        execute();
                    } else {
                        setState(query);
                    }
                }
            }
        }

        if (options.manual === true) {
            skip = true;
        }

        if (!state.data && !state.loading && !skip) {
            execute();
        }

        socket.on(event, handleResponse);

        return () => {
            socket.off(event, handleResponse);
        }
    }, []);

    return {
        state,
        executeQuery
    };
}
