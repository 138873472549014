import { useNavigate, useSearchParams } from "react-router-dom";
import { TypographyH3 } from "../ui/Typography";
import { BackToResourcesLink } from "./BackToResourcesLink";
import { SearchControl } from "./SearchControl";
import { useContext, useEffect, useState } from "react";
import { AskDesiaLink } from "./AskDesiaLink";
import { SearchResults } from "./SearchResults";
import { DedupedSearchQueryItem, DESIA_EVENT, ResponseSearchQuery, WebSocketResponseWrapper } from "@/types/types";
import { groupByFile } from "@/utils/search";
import { WebSocketContext } from "@/contexts/WebSocketContext";

export function ResourceSearchPage({
    loading,
    error,
    data,
    handleSearch: onExecuteSearch,
}: {
    loading: boolean,
    error: boolean,
    data: WebSocketResponseWrapper<ResponseSearchQuery> | null,
    handleSearch: (q: string) => void
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get("q");
    const navigate = useNavigate();

    function handleSearch(nextSearchQuery: string) {
        if (nextSearchQuery === "") {
            return;
        }
        setSearchParams({ q: nextSearchQuery });
    }

    function executeSearch(q: string) {
        onExecuteSearch(q);
    }

    function handleAskDesia() {
        navigate(`/assistant/ask?q=${searchQuery}&trigger=true&mode=simple`);
    }

    useEffect(() => {
        if (searchQuery) {
            executeSearch(searchQuery);
        }
    }, [searchQuery]);

    const dedupedResults: DedupedSearchQueryItem[] = groupByFile(data?.data || []);

    return (
        <div className="flex flex-col gap-2">
            <BackToResourcesLink />

            <div>
                <div className="flex flex-col gap-12">
                    <div className="text-center">
                        <TypographyH3>Documents</TypographyH3>
                    </div>

                    <SearchControl handleSearch={handleSearch} initialValue={searchQuery} />

                    <div className="flex flex-col gap-6">
                        <AskDesiaLink query={searchQuery} handleClick={handleAskDesia} />

                        <SearchResults
                            loading={loading}
                            error={error}
                            data={dedupedResults}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ResourceSearchPageContainer() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState<WebSocketResponseWrapper<ResponseSearchQuery> | null>(null);
    const secureSocket = useContext(WebSocketContext);
    const socket = secureSocket.socket!;

    function handleSearchResults(res: WebSocketResponseWrapper<ResponseSearchQuery>) {
        setResults(res);
        if (res.error) {
            setError(true);
        }
        setLoading(false);
    }

    function handleSearch(q: string) {
        setLoading(true);
        socket.emit(DESIA_EVENT.SEARCH_QUERY, { query: q });
    }

    useEffect(() => {
        socket.on(DESIA_EVENT.SEARCH_QUERY, handleSearchResults);

        return () => {
            socket.off(DESIA_EVENT.SEARCH_QUERY, handleSearchResults);

        }
    })

    return (
        <ResourceSearchPage
            loading={loading}
            error={error}
            data={results}
            handleSearch={handleSearch}
        />
    )
}
