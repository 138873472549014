import { useContext, useEffect } from "react";
import { ASSISTANT_ACTIONS, AssistantStoreContext, SourceDocumentId, getLiveConversationById } from "../../contexts/AssistantContext"
import { clearIdentifier, getIdentifier, getTimestamp } from "../../utils/utils";
import { useSocketQuery } from "../../hooks/useSocketQuery";
import { AssistantAskMode, DESIA_EVENT, RequestAssistantAsk, ResponseChatStream, WebSocketRequestWrapper } from "../../types/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorMessage } from "../ErrorMessage";
import { Conversation } from "./Conversation";
import { UserContext } from "../../contexts/UserContext";

export function NewConversation() {
    const { store, dispatch } = useContext(AssistantStoreContext);
    const { settings } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const requestId = searchParams.get("request_id") || "unknown";
    const q = searchParams.get("q");
    const mode = searchParams.get("mode") as AssistantAskMode;
    const trigger = searchParams.get("trigger");
    const conversationId = searchParams.get("conversation_id");

    function handleSelectCitation(messageId: string, documentId: SourceDocumentId[]) {
        dispatch({
            action: ASSISTANT_ACTIONS.SELECT_CITATION,
            data: { messageId, documentId }
        })
    }

    function handleShowAllCitations(messageId: string) {
        dispatch({
            action: ASSISTANT_ACTIONS.SHOW_ALL_CITATIONS,
            data: { messageId }
        })
    }

    const { executeQuery, state: queryState } = useSocketQuery({
        event: DESIA_EVENT.CHAT_ASK,
        request: {
            requestId: requestId,
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: true,
            callback: (response) => {
                const res = response.data as ResponseChatStream; // fixme
                dispatch({
                    action: ASSISTANT_ACTIONS.STREAMING_RESPONSE,
                    data: {
                        ...res,
                        requestId: response.requestId,
                        timestamp: response.timestamp,
                    }
                })
            }
        },
    })

    const conversation = getLiveConversationById({
        store,
        requestId,
        conversationId: conversationId || ""
    })

    function handleAsk({ message, mode }: { message: string, mode: AssistantAskMode }) {
        // @ts-expect-error
        const c = conversation.find(c => !!c.conversationId || !!c.conversation_id);
        if (!c) {
            throw new Error("conversationId not found")
        }
        // @ts-expect-error
        const conversationId = c.conversationId || c.conversation_id;
        const timestamp = getTimestamp();

        dispatch({
            action: ASSISTANT_ACTIONS.FOLLOW_UP_ASK,
            data: {
                conversationId,
                requestId: `${requestId}_followup_${conversation.length}`,
                question: message,
                timestamp,
                mode,
            }
        })
        const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
            requestId,
            timestamp,
            params: {
                message: message,
                conversationId,
                connectorsV2: settings.assistant.sources,
                model: settings.assistant.parameters.model,
                seed: settings.assistant.parameters.seed,
                temperature: settings.assistant.parameters.temperature,
                systemPrompt: settings.assistant.parameters.systemPrompt,
                mode
            }
        }
        executeQuery({
            event: DESIA_EVENT.CHAT_ASK,
            request
        });
    }

    useEffect(() => {
        if (conversationId) {
            navigate(`/assistant/conversation/${conversationId}`);
            return;
        }

        if (requestId) {
            const identifiers = getIdentifier(requestId);
            if (identifiers) {
                clearIdentifier(requestId);
                navigate(`/assistant/conversation/${identifiers.conversationId}`);
                return;
            }
        }

        if (q && trigger) {
            const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
                requestId,
                timestamp: getTimestamp(),
                params: {
                    message: q,
                    connectorsV2: settings.assistant.sources,
                    model: settings.assistant.parameters.model,
                    seed: settings.assistant.parameters.seed,
                    temperature: settings.assistant.parameters.temperature,
                    systemPrompt: settings.assistant.parameters.systemPrompt,
                    mode: mode || 'simple' // fixme
                }
            }
            executeQuery({
                event: DESIA_EVENT.CHAT_ASK,
                request
            });
            setSearchParams(prev => {
                prev.delete("trigger");
                return prev;
            });
        }
    }, [])

    if (!requestId) {
        return <ErrorMessage message={`We failed to ask that question: missing request_id or question`} />
    }

    if (!conversation.length) {
        return <ErrorMessage message="Something unexpected occurred, try again" />
    }

    return <Conversation
        handleAsk={handleAsk}
        selectCitation={handleSelectCitation}
        showAllCitations={handleShowAllCitations}
        selectedCitations={store.state.selectedCitations}
        conversation={conversation}
        queryState={queryState}
        sources={settings.assistant.sources}
    />
}
