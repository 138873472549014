import { connect } from "@/features/userSlice";
import { ReactNode, createContext, useEffect } from "react";
import { useDispatch } from "react-redux";

export const ConnectionContext = createContext<null>(null);

function ConnectionProvider({ children }: { children: ReactNode }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(connect());
    }, [])
    
    return (
        <ConnectionContext.Provider value={null}>
            {children}
        </ConnectionContext.Provider>
    )
}

export { ConnectionProvider };
