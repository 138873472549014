import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import "./ExtendedOptions.css";
import { SourceConnector } from "../../types/types";

export function ExtendedOptions() {
    const { settings, updateSettings } = useContext(UserContext);

    const sources = settings.assistant.sources;
    function toggleSource(source: SourceConnector) {
        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    sources: settings.assistant.sources.find((v) => v.id === source.id) ? settings.assistant.sources.filter(s => s.id !== source.id) : [...settings.assistant.sources, source]
                }
            }
        })
    }

    return (
        <div>
            <div className="extendedoptions-container">
                <div className="extendedoptions-row">
                    <span className="extendedoptions-title">Sources:</span>
                    <span>
                        <span className="extendedoptions-option">
                            <input id="checkbox-internal-search" type="checkbox" checked={!!sources.find((v) => v.id === 'internal-search')}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={() => {
                                    toggleSource({ id: 'internal-search' })
                                }} />
                            <label htmlFor="checkbox-internal-search"
                                className="ms-2 text-sm font-medium text-gray-600 dark:text-gray-300"
                            >
                                Internal documents
                            </label>
                        </span>
                        <span className="extendedoptions-option">
                            <input id="checkbox-internal-search" type="checkbox" checked={!!sources.find((v) => v.id === 'web-search')}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={() => {
                                    toggleSource({ id: 'web-search' })
                                }} />
                            <label htmlFor="checkbox-web-search"
                                className="ms-2 text-sm font-medium text-gray-600 dark:text-gray-300"
                            >
                                Web
                            </label>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}
