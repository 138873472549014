import { UserContext } from "@/contexts/UserContext"
import { ResourceFilterOption } from "@/types/types"
import { captialise, friendlyOrgName } from "@/utils/utils"
import { useContext } from "react"

export function ResourceFilter({ selectedFilter, handleFilterChange }: { selectedFilter: ResourceFilterOption, handleFilterChange: (filterName: ResourceFilterOption) => void }) {
    const userContext = useContext(UserContext)
    const filters: { name: ResourceFilterOption, isSelected: boolean }[] = [
        {
            name: 'all documents',
            isSelected: selectedFilter === 'all documents',
        },
        {
            name: 'company-wide',
            isSelected: selectedFilter === 'company-wide',
        },
        {
            name: 'desia library',
            isSelected: selectedFilter === 'desia library',
        },
    ]
    return (
        <div className="flex gap-8 border-b">
            {filters.map((f) => {
                return (
                    <div
                        key={f.name}
                        className={`font-label sm:font-body hover:cursor-pointer pb-2 ${f.isSelected ? '!font-label-strong sm:!font-body-strong border-b-2 border-system-primary text-system-primary' : 'text-system-body'}`}
                        onClick={() => {
                            handleFilterChange(f.name)
                        }}
                    >
                        {f.name === 'company-wide' ? friendlyOrgName(userContext.user?.app_metadata.organization_id) : captialise(f.name)}
                    </div>
                )
            })}
        </div>
    )
}
