import { AssistantAskMode } from "../../types/types";
import { TypographyBody, TypographyH2 } from "../ui/Typography";
import { ChatBox } from "./ChatBox";

interface Props {
    expertModeAvailable: boolean;
    hasSourceSelector: boolean;
    handleSubmit: ({ message, }: { message: string, mode: AssistantAskMode }) => void;
}
export function NewAsk({ expertModeAvailable, handleSubmit }: Props) {
    return (
        <div className="flex justify-center align-center h-full mt-10">
            <div className="flex flex-col justify-center align-center w-full max-w-[720px]">
                <div className="flex flex-col gap-4 text-center">
                    <TypographyH2>Ask Desia</TypographyH2>
                    <TypographyBody className="text-system-body">
                        Level up your investment research.
                        <br />
                        Minutes, not hours.
                    </TypographyBody>
                </div>

                <div className="flex justify-center my-20">
                    <ChatBox
                        expertModeAvailable={expertModeAvailable}
                        handleSubmit={handleSubmit}
                        additionalControls={true}
                        status="Ready"
                        isConversation={false}
                        showBorder={false}
                    />
                </div>
            </div>
        </div>
    )
}
